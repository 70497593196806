import React, { Component } from 'react';
import { Footer as ComFooter } from '@sfx/react-ui';

class Footer extends Component {

    render() {
        const links = [{ link: '/About', name: 'About', target: '_self' },
        { link: 'https://www.hexagonmi.com/about-us/terms-of-sale/sfx-cloud-service-agreement/', name: 'Legal' },
        { link: 'https://www.hexagonmi.com/en-US/about-us/privacy-policy', name: 'Privacy' },
        { link: 'https://www.hexagonmi.com/en-US/about-us/privacy-policy', name: 'Cookies' },
        { link: 'https://www.hexagonmi.com/contact-us', name: 'Contact' }];

        const version = sessionStorage.getItem('appVersion');
        const copyright = `${(new Date()).getFullYear()} Hexagon AB and/or its subsidiaries.`;

        return (
            <ComFooter
                copyright={copyright}
                version={version}
                links={links}
            />
        )
    }
}

export default Footer;