const regUrl = {
    reg: /(http|https):\/\/([\w\-_]+(.[\w\-_]+)+|localhost)([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/,
    message: 'Please input correct URL'
};
const regMessyCode = {
    reg: /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/,
    message: 'Please input correct value'
};
const validateEmail = {
    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Please input valid email!'
}

const validatePhoneNumber = (value) => {
    let valid = true;
    let message = '';
    const reg = /^(\+?( |-|\.)?\d{1,3}( |-|\.)?)?(\(\+?\d{1,5}( |-|\.)?\d{0,3}\)|\d{2,4})( |-|\.)?(\d{2,}( |-|\.)?\d+)$/;
    const regSpecial = /[!"#$%&'*,:;<=>?@[\\\]^_`{|}~]+/;

    if(value.length > 20) {
        valid = false;
        message = 'The maximum length for phone number is 20.'
        return { valid, message }
    }

    if (regSpecial.test(value)) {
        valid = false;
        message = 'Please only use +, -, ( ), ., and spaces to divide phone numbers in this section.'
        return { valid, message, type: 'special' }
    }
    if (!reg.test(value)) {
        valid = false;
        message = 'Please input valid mobile';
    }
    return { valid, message }
}

const formValidateReg = {
    regUrl,
    regMessyCode,
    validateEmail,
    validatePhoneNumber
}

export default formValidateReg;