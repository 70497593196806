import React, { Component } from 'react';
import { Modal, Form, Select, Tooltip, Row, Col, Icon,Pagination,Button } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import alertMessage from '../commonComponents/alertMessage';
import { getSolutionListAsync } from '../solution/solution.service';
import {addNewVouchers, checkMachineSerialNumbersAsync} from '../voucherCode/voucherCode.service'
import styles from './newVouchers.module.scss'
import TextArea from 'antd/lib/input/TextArea';
import { CSVLink } from "react-csv";
import FixedHeaderTable from '../commonComponents/table';
import { copyToClipboard } from '../../utils/util';
import ViewVouchers from './viewVouchers';

const { Option } = Select;
class NewVouchersForm extends Component {
    state = {
        showForm: true,
        showTable: false,
        solutionList: [],
        tableData: [],
        voucherData: [],
        csvData: [],
        total: 0,
        loadingTable: false,
        loadingExport: false,
        isValidating: false,
        isFetching: false,
        pageIndex: 1,
        pageSize: 10,
        rowId: '',
        isDisabled: false,
        showViewVouchers: false,
        editVoucherInfo: {},
        lineCount: 0
    }

    columns = [
        {
            title: 'Voucher Code',
            dataIndex: 'code',
            key: 'code',
            width: '22%',
            sorter: false,
            render: text => (
                <Tooltip placement="right" trigger={'click'} title={`${text} is copied!`}>
                    <div className={styles.pointerCursor} onClick={this.clickToCopy(text)}>{text}</div>
                </Tooltip>)
        },
        {
            title: 'Machine Serial Number',
            dataIndex: 'machineSerialNumber',
            key: 'machineSerialNumber',
            width: '20%',
            sorter: false,
            render:  text => (
                <Tooltip placement="right" trigger={'click'} title={`${text} is copied!`}>
                    <div className={styles.pointerCursor} onClick={this.clickToCopy(text)}>{text}</div>
                </Tooltip>)       
        },
        {
            title: 'Subscription Duration',
            dataIndex: 'subscriptionDuration',
            key: 'subscriptionDuration',
            width: '20%',
            sorter: false,
            render: (text, record) => {
                let displayTest = '--';
                switch(text) {
                    case '45d':
                        displayTest = '45 Days';
                        break;
                    case '1y':
                        displayTest = '1 Year';
                        break;
                    case '2y':
                        displayTest = '2 Years';
                        break;
                    case '3y':
                        displayTest = '3 Years';
                        break;
                    default:
                        displayTest = '--';
                        break;
                }

                return <div>{displayTest}</div>
            }
        },
        {
            title: 'Date expired',
            width: '16%',
            sorter: false,
            key: 'expiredDate',
            dataIndex: 'expiredDate',
            render: (text, record) => {
                text = record.status === 'Consumed' ? record.consumedDate : record.expiredDate
                let html = <span>
                    {text && moment.utc(text).local().format('YYYY-MM-DD HH:mm:ss')}
                </span>
                return text ? html : '--'
            }
        }
    ];
   
    getSolutionList() {
        getSolutionListAsync({
            SearchText: 'Asset Management',
            PageSize: 100,
            Index: 1
        }, { catchError: true })
          .then(res => {
            this.setState({
              solutionList: res.data.rows
            })
          })
      }
    
    componentDidMount() {
        this.getSolutionList();
    }

    componentWillUnmount() {
        this.timer && clearTimeout(this.timer);

        this.setState = (state, callback) => {
            return
        }
    }

    hasErrors = (fieldsError) => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    };

    composeCsvFileName = () => {
        if (this.state.csvData.length === 1) {
            return 'Voucher Metrology Asset Manager ' + this.state.csvData[0]['Associated Machine Serial Number'] + ' ' + moment.utc().local().format('YYYY-MM-DD HH_mm_ss') + '.csv'
        } else if (this.state.csvData.length > 1) {
            return 'Vouchers Metrology Asset Manager (' + this.state.csvData.length + ') ' + moment.utc().local().format('YYYY-MM-DD HH_mm_ss') + '.csv';
        }
    }

    formatTableData = (data) => data.map(item => ({
        key: item.id,
        ...item
    }))

    onSubmit = () => {
        const form = this.props.form;
        let request, res = {}, message = '', sendData = {};
        form.validateFields(async (err, data) => {
            if (err) {
                return;
            }
            this.setState({ isFetching: true });

            const solutionId = data.solutionId
            const machineSerialNumberList = data.machineSerialNumbers.split("\n").map(x => x.trim());
            const subscriptionDuration = data.subscriptionDuration
                sendData = {
                    solutionId: solutionId,
                    machineSerialNumberList: machineSerialNumberList,
                    subscriptionDuration: subscriptionDuration
                };
                this.setState({editVoucherInfo: sendData});
                request = () => addNewVouchers(sendData);
                message = "Created";
            try {
                res = await request();
                if (res && res.status === 200) {
                    alertMessage.success(`${message} voucher(s) successfully`);
                    this.setState({ 
                        showTable: true, 
                        showForm: false,
                        voucherData: res.data.item1,
                        total: res.data.item1.length}, 
                        () => { this.getPageData(this.state.pageIndex, this.state.pageSize, res.data.item1)});
                }
               this.setState({ isFetching: false });
            } catch {
                this.setState({ isFetching: false });
            }
        });
    }

    validateMachineSerialNumbers = (rule, value, callback) => {
        this.setState({ isValidating: true });
        if (value && value.trim()) {
            let serialNumberArray = value.split("\n").map(y => y.trim());
            this.setState({lineCount: serialNumberArray.length});
            let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
            let duplicatedArray = [...new Set(findDuplicates(serialNumberArray))]
            let serialEmptyArray = serialNumberArray.filter(y => y.length === 0);
            let serialLengthArray = serialNumberArray.filter(y => y.length > 20);
            if (serialEmptyArray.length > 0){
                callback('One or more machine serial numbers is/are empty string(s)');
                this.setState({ isValidating: false });   
            } else if (serialLengthArray.length > 0){
                callback(`Length of machine serial number(s) ${serialLengthArray.toString()} is more than 20`);
                this.setState({ isValidating: false });   
            } else if(serialNumberArray.length > 100){
                callback('Maximum 100 Machine serial numbers are allowed to enter');
                this.setState({ isValidating: false });   
            } else if (duplicatedArray.length > 0){
                callback(`Machine serial number(s) ${duplicatedArray} is/are duplicated`);
                this.setState({ isValidating: false });   
            } 
            else {
                this.checkMachineSerialNumbersRegisted({machineSerialNumbers:serialNumberArray}, callback);
            }   
        } 
        else if (!value) {
            callback();
            this.setState({ isValidating: false, lineCount: 0 });
        } else {
            callback('The serial number consists entirely of blank spaces');
            this.setState({ isValidating: false, lineCount: 0  });
        }
    }

    checkMachineSerialNumbersRegisted = debounce((value, callback) => {
        checkMachineSerialNumbersAsync(value, { catchError: true }).then(resp => {
                const { data } = resp
                if (resp.status === 200 && data) {
                    let lookup = {};
                    let items = data;
                    let result = [];
                    for (let item, i = 0; item = items[i++];) {
                        var name = item.machineSerialNumber;
                        if (!(name in lookup)) {
                            lookup[name] = 1;
                            result.push(name);
                        }
                    } 
                callback(`Machine serial number(s) ${result.toString()} is/are already associated with some vouchers.`);
            }
            this.setState({ isValidating: false });

        }).catch(err => {
            if (err.response.status === 404) {
                callback()
            }
            this.setState({ isValidating: false });
        })
    }, 2000)

    clickToCopy = (key) => {
        return (e) => {
            e.stopPropagation();
            this.setState({
                rowId: ''
            });
            copyToClipboard(key);
        }
    }

    // set row style
    setRowClassName = (record) => {
        return record.id === this.state.rowId ? 'click-row-style' : '';
    }

      // pagination
    onPageChange = (page, pageSize) => {
        this.setState({
            pageIndex: page
        }, () => {
            this.getPageData(page, this.state.pageSize, this.state.voucherData)
        })
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            rowId: '',
            pageIndex: current,
            pageSize: pageSize
        }, () => {
            this.getPageData(current, pageSize, this.state.voucherData);
        })
    }

    getPageData = (current, pageSize, dataArray) => {
        var maxLength = pageSize * current -1;
        var minLength = current * pageSize - pageSize;
        var pageData = [];
        for(var i = minLength; i< dataArray.length; i++){
            if (maxLength < i ) {
                break;
            } else {
                pageData.push(dataArray[i]);
            }
        }
        pageData = this.formatTableData(pageData);
        this.setState({tableData: pageData});
    }

    prepareCsvData = (data) => data.map(item => ({
        'Voucher Code': item.code,
        'Associated Machine Serial Number': item.machineSerialNumber,
        'Creation Date': moment.utc(item.createdDate).local().format('YYYY-MM-DD HH:mm:ss') + '\t',
        'Creator': item.creator ? item.creator.givenName + ' ' + item.creator.surname : '',
        'Expiry Date': moment.utc(item.expiredDate).local().format('YYYY-MM-DD HH:mm:ss') + '\t',
        'Subscription Duration': this.formatSubscriptionDuration(item),
        'Status': item.status,
        'Solution': 'Metrology Asset Manager'
    }))

    formatSubscriptionDuration = (voucher) => {
        let subscriptionDuration = '';
        if (voucher) {
            switch (voucher.subscriptionDuration) {
                case '45d':
                    subscriptionDuration = '45 Days';
                    break;
                case '1y':
                    subscriptionDuration = '1 Year';
                    break;
                case '2y':
                    subscriptionDuration = '2 Years';
                    break;
                case '3y':
                    subscriptionDuration = '3 Years';
                    break; 
                default:
                    subscriptionDuration = '';
                    break;
            }
        }
        return subscriptionDuration;
    }

    exportCSV = (event) => {
        alertMessage.info('Preparing CSV data...Please wait');
        this.setState({
            csvData: []
        }, () => {
            let csvData = [];
            this.setState({
                loadingExport: true
            })
            csvData = this.prepareCsvData(this.state.voucherData);

            this.setState({ csvData, loadingExport: false }, () => {
                this.timer = setTimeout(() => {
                    // Ensure CSV Link retrieve the correct data in React state
                    this.csvLink.link.click();
                }, 100);
            })
        })
    }

    toggleViewVouchers = () => {
        this.setState({ showViewVouchers: !this.state.showViewVouchers })
    }

    render() {
        const { getFieldDecorator, isFieldsTouched, getFieldsError } = this.props.form;
        const { csvData, loadingExport, loadingTable, isFetching, isValidating ,isDisabled } = this.state;
    
        return (
            <>
            {this.state.showViewVouchers && <ViewVouchers toggle={this.toggleViewVouchers} />}
            <Modal
                maskClosable={false}
                closable={true}
                title={'New Vouchers'}
                visible={true}
                bodyStyle={{ padding: '10px', overflowY: 'auto' , maxHeight: 630 }}
                footer={null}
                width='1000px'
                onCancel={this.props.toggle}
            >
               <div>
               {this.state.showForm && 
                <div>
                        <div className={styles.wrap}>
                            <Form
                                className={styles.form}
                                layout="horizontal"
                                size={'default'}
                            >
                                <div className={styles.content} >
                                    <div className={styles.PopupContainer} id='new-vouchers-select-popup-container'>       
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item label="Solution">
                                                        {getFieldDecorator('solutionId', {
                                                            rules: [{ 
                                                                        required: true, 
                                                                        message: 'Please select solution!' }
                                                                    ],
                                                            initialValue: this.state.solutionList.find(sln => sln.name === 'Asset Management') ?  this.state.solutionList.find(sln => sln.name === 'Asset Management').id: ''
                                                        })(<Select
                                                            getPopupContainer={() => document.getElementById('new-vouchers-select-popup-container')}
                                                            showSearch
                                                            optionFilterProp='children'
                                                            disabled={isDisabled}
                                                        >
                                                            {
                                                                this.state.solutionList.filter(sln => sln.name === 'Asset Management').map(item => <Option key={item.id} value={item.id}>{item.name === 'Asset Management' ? 'Metrology Asset Manager' : item.name}</Option>)
                                                            }
                                                        </Select>)
                                                        }
                                                    </Form.Item>
                                                    <Form.Item label="Subscription Duration">
                                                        {getFieldDecorator('subscriptionDuration', {
                                                            rules: [
                                                                    { required: true, 
                                                                    message: 'Please select a duration for the subscription.' }
                                                                   ],
                                                        })(<Select
                                                            getPopupContainer={() => document.getElementById('new-vouchers-select-popup-container')}
                                                            disabled={isDisabled}>
                                                            <Option value="45d">45 Days</Option>
                                                            <Option value="1y">1 Year</Option>
                                                            <Option value="2y">2 Years</Option>
                                                            <Option value="3y">3 Years</Option>
                                                        </Select>)
                                                        }
                                                    </Form.Item>
                                                </Col> 
                                                 <Col span={12}>
                                                    <Form.Item label={`Machine Serial Number (Press enter for multi lines ${this.state.lineCount}/100)`} hasFeedback>
                                                        {getFieldDecorator('machineSerialNumbers', {
                                                        initialValue: "",
                                                        rules: [
                                                            { required: true, 
                                                                message: 'Please enter Machine Serial Number(s) in one or multi lines',
                                                                whitespace: false 
                                                            },
                                                            {  max: 2000, 
                                                                message: 'The maximum length for machine serial number is 2000 characters' 
                                                            },
                                                            { validator:  this.validateMachineSerialNumbers }
                                                        ],
                                                        })(<TextArea className={styles.textarea}
                                                            autoComplete="off" 
                                                            rows={6} 
                                                            maxLength={2000} 
                                                            disabled={isDisabled}
                                                            />)}
                                                    </Form.Item>
                                                </Col> 
                                            </Row>
                                    </div>
                                </div>                          
                            </Form >
                        </div>
                        <div>
                            <Button 
                                className={styles.viewAllBtn} 
                                type="primary" 
                                onClick={this.toggleViewVouchers}
                            >
                                View all vouchers
                            </Button>
                        </div>
                        <div className={styles.buttonRight}> 
                            <Button 
                                disabled={isFetching} 
                                className={styles.btn} 
                                key="cancel" 
                                type="primary" 
                                icon="close" 
                                onClick={this.props.toggle}
                            >
                                Cancel
                            </Button>
                            <Button 
                                disabled={isFetching || this.hasErrors(getFieldsError())|| !isFieldsTouched() || isValidating } 
                                className={styles.btn}  
                                key="submit" 
                                type="primary"
                                icon={isFetching ? 'loading' : 'check'} 
                                onClick={this.onSubmit}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>}
                    {this.state.showTable && <div className={styles.newVouchersViewer}>
                    <div className='main-container-wrap'>
                        <Row type="flex" align="bottom">
                            <Col span={5}>
                                <CSVLink
                                    data={csvData}
                                    filename={this.composeCsvFileName()}
                                    hidden
                                    ref={(r) => this.csvLink = r}
                                    target="_blank"
                                >
                                    <Icon type='export'></Icon>
                                    <span>CSV</span>
                                </CSVLink>
                                <Button loading={loadingExport} style={{ marginRight: '10px' }} onClick={this.exportCSV} disabled={loadingTable || loadingExport} icon='export' className="ant-btn ant-btn-primary">CSV</Button>
                            </Col>
                        </Row>
                        <FixedHeaderTable
                            size='middle'
                            loading={loadingTable}
                            pagination={false}
                            rowClassName={this.setRowClassName}
                            columns={this.columns} dataSource={this.state.tableData} />
                        <br />
                        {
                            this.state.total !== 0 && <Pagination
                                showSizeChanger
                                onChange={this.onPageChange}
                                current={this.state.pageIndex}
                                onShowSizeChange={this.onShowSizeChange}
                                total={this.state.total}
                                showTotal={total => `Total ${this.state.total} items`} />
                        }
                        <br/>
                        <div>
                            <Button 
                                className={styles.viewAllBtn} 
                                type="primary" 
                                onClick={this.toggleViewVouchers}
                            >
                                View all vouchers
                            </Button>
                        </div>
                    </div></div>}
                </div>
            </Modal>
            </>
        )
    }
}

const NewVouchers = Form.create({ name: 'newVouchersCode' })(NewVouchersForm);

export default NewVouchers;
