import React from 'react'
import ReactDOM from 'react-dom'
import Menus from './popupMenu'

function createHandlerMenu() {
    const div = document.createElement('div')
    document.body.appendChild(div)
    const handlerMenu = ReactDOM.render(<Menus />, div)
    return {
        addMenus(content,data) {
            return handlerMenu.addMenus(content,data);
        },
        destroy() {
            ReactDOM.unmountComponentAtNode(div)
            document.body.removeChild(div)
        }
    }
}

let handlerMenu;

const menus = (content,data) => {
    if (handlerMenu) {
        handlerMenu.destroy();
    }
    handlerMenu = createHandlerMenu();
    setTimeout(() => {
        handlerMenu.addMenus(content,data)
    }, 0)
}

const popupMenu = {
    show: (content, data) => {
        return menus(content,data);
    },
    close: ()=>{
        if(handlerMenu) {
            handlerMenu.destroy();
            handlerMenu = null
        }
    }
}

export default popupMenu;