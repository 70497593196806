import React, { Component } from 'react'
import { Input, Select } from 'antd';

export default class CallingCodePhone extends Component {
    static getDerivedStateFromProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps) {
            return {
                ...(nextProps.value || {}),
            };
        }
        return null;
    }

    constructor(props) {
        super(props);
        const value = props.value || {};
        this.state = {
            number: value.number,
            countryCodeGeoNameId: value.countryCodeGeoNameId,
            countryCode: value.countryCode,

        };
    }

    handleNumberChange = e => {
        const number = e.target.value.trim();
        if (!('value' in this.props)) {
            this.setState({ number });
        }
        this.triggerChange({ number });
    };

    handleCountryCodeChange = (countryCodeGeoNameId, option) => {
        const countryCode = option.props.code
        if (!('value' in this.props)) {
            this.setState({
                countryCodeGeoNameId,
                countryCode
            });
        }
        this.triggerChange({ countryCodeGeoNameId, countryCode });
    };

    triggerChange = changedValue => {
        // Should provide an event to pass value to Form.
        const { onChange } = this.props;
        if (onChange) {
            onChange({
                ...this.state,
                ...changedValue,
            });
        }
    };

    filterOption = (input, option) =>
        option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0

    render() {
        const { children, disabled, loading } = this.props;
        const { countryCodeGeoNameId, number, countryCode } = this.state;
        const prefixSelector = <Select
            className="calling-code-select"
            showSearch
            disabled={disabled}
            loading={loading}
            getPopupContainer={() => document.getElementById('select-popup-container')}
            value={loading ? (countryCode && `(+${countryCode})`) : countryCodeGeoNameId}
            filterOption={this.filterOption}
            onChange={this.handleCountryCodeChange}
        >
            {children}
        </Select>
        return (
            <span
                id='select-popup-container'
                className='prefix-wrap select-popup-container'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}>
                <Input
                    type="text"
                    value={number}
                    disabled={disabled || !countryCodeGeoNameId}
                    onChange={this.handleNumberChange}
                    addonBefore={prefixSelector}
                />
            </span>
        );
    }
}