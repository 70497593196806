import React from 'react'
import ReactDOM from 'react-dom'
import AlertMessage from './alertMessage.component';

function createAlert(option) {
    const div = document.createElement('div')
    document.body.appendChild(div)
    const alertMessage = ReactDOM.render(
        <AlertMessage
            type={option.type}
            message={option.message}
            description={option.description} />,
        div)

    return {
        hide() {
            alertMessage.hide();
        },
        show() {
            alertMessage.show();
        },
        destroy() {
            ReactDOM.unmountComponentAtNode(div)
            document.body.removeChild(div)
        }
    }
}


let createdMessage;
let timer;

const createMsg = (type, message, description) => {
    if (createdMessage) {
        createdMessage.destroy();
    }
    createdMessage = createAlert({ type, message, description });
    setTimeout(() => {
        createdMessage.show();
    }, 0)

    timer = setTimeout(() => {
        if(createdMessage) {
            createdMessage.hide();
            clearTimeout(timer);
        }
    }, 5000)

}



const alertMessage = {
    success: (description) => createMsg('success', 'Success', description),
    info: (description) => createMsg('info', 'Tip', description),
    warning: (description) => createMsg('warning', 'Warning', description),
    error: (description) => createMsg('error', 'Error', description),
}

export default alertMessage;