import React, { Component } from 'react';
import { Table } from 'antd';
import './table.module.scss'
class FixedHeaderTable extends Component {

    render() {
        let headerProps = {
            pagination: false,
            dataSource: [],
            className: 'fix-header-table-wrap',
            loading: false,
            scroll: false
        }
        headerProps = Object.assign({ ...this.props, ...headerProps })
        return (<React.Fragment>
            <Table {...headerProps} />
            <Table {...this.props} showHeader={false} />
        </React.Fragment>)
    }
}
export default FixedHeaderTable