
const initialState = {
    user: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_LOADED':
            return {
                ...state,
                user: action.payload
            };
        case 'USER_LOGOUT':
            return {
                ...state,
                user: null
            }
        default:
            return state;
    }
}

export default reducer;