export const zh = {
    // Header
    fullTitle: 'HxGN SFx',
    shortTitle: 'HxGN SFx',
    signIn: 'Sign in',

    // Home
    smartFactoryAssetManagement: 'HxGN SFx',
    minimizeDowntimeMaximizeEfficiency: 'Minimize downtime. Maximize efficiency.',
    landingPageDescription: "Managing your measurement systems is critical to maximizing the value of your investment. Asset management begins immediately after a system enters service. You want to be sure that any anomalies in its operation are immediately known and addressed. You also want to know that the asset is in use during scheduled operating hours and that you have the proper tools to optimize its utilization over time. Finally, you want to be prepared for maintenance, so that service needs do not interrupt your scheduled operations. Use HxGN SFx | Asset Management from the start to take control of your measurement system’s performance and overall effectiveness.",

    // Login
    rememberMe: 'Remember Me',
    loginFailedReponse: 'Sorry, the email and/or password you entered is incorrect. Please try again or ',
    loginFailedContact: 'contact our customer service.',
    userDisabledResponseStart: 'Sorry, this email account has been disabled. Please contact your ',
    userDisabledResponseEnd: 'or refer to the disabled account help pages for more information.',
    userDisabledContact: 'Hexagon support ',
    // Footer
    copyright: '2019 Hexagon AB and/or its subsidiaries.',
    contactUs: 'Contact Us',
    about: 'About',
    legal: 'Legal',
    privacy: "Privacy",
    cookies: "Cookies",
    contact: 'Contact',
    errorRequired: 'Field is not allowed to be empty',
    errorMaxChar: 'Exceed field max length {0}',
    errorMinChar: 'Exceed field min length {0}'
}