export const logout = payload => {
    return {
        type: 'USER_LOGOUT',
        payload
    }
}
export const updateUser = payload => {
    return {
        type: 'USER_LOADED',
        payload
    }
}
export const updateSolutions = payload => {
    return {
        type: 'UPDATE_SOLUTIONS',
        payload
    }
}