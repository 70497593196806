import apiService from '../../services/api.service';

export const getVoucherCodes = (body, options) => {
  return apiService.post('/api/licenses/voucher/codes', body, options)
};

export const addNewVoucher = (body, options) => {
  return apiService.post('/api/licenses/voucher/asset', body, options);
};

export const addNewVouchers = (body, options) => {
  return apiService.post('/api/licenses/voucher/asset/batch', body, options);
};

export const editVoucher = (body, options) => {
  return apiService.put('/api/vouchercode', body, options);
};

export const deleteVoucherAsync = (id) => {
  return apiService.delete(`/api/licenses/voucher/${id}`)
};

export const checkMachineSerialNumberAsync = (serialNumber, options) => {
  return apiService.get(`/api/licenses/voucher/checkSN/${serialNumber}`, undefined, options)
};

export const checkMachineSerialNumbersAsync = (body, options) => {
  return apiService.post('/api/licenses/voucher/checkSN/batch', body, options)
};

