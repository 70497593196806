import React, { Component } from 'react';
import {
    Modal,
    Form,
    Input,
    Select,
    InputNumber,
    DatePicker,
    Row, Col, Icon
} from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import alertMessage from '../commonComponents/alertMessage';
import { validateEmailAsync, GetCallingCodeApi, newOrgAndAccountAsync } from '../user/user.service';
import { GetCountryApi, GetStateApi, CheckOrgApi, GetOrgSolutionsApi, CheckExecSalesFieldsApi } from '../organization/organization.service'
import { getSolutionListAsync } from '../solution/solution.service'
import { addNewLicense } from '../license/license.service'
import CallingCodePhone from '../commonComponents/callingCodePhone.component';
import formValidateReg from '../../utils/formValidate'
import styles from './newOrganizationAndAccount.module.scss'
import { LICENSE_TIER, ISSUE_DATE } from '../license/licenseTable.component';
import { MAX_SUBSCRIPTION_NUMBER } from '../license/constant'

const { Option } = Select;
const { validatePhoneNumber } = formValidateReg;

class NewOrganizationAndAccountForm extends Component {
    state = {
        loadingCallingCode: false,
        callingCodeData: [],
        loadingState: false,
        stateData: [],
        loadingCountry: false,
        CountryData: [],
        subscriptionPackage: '',
        countryGeoNameId: '',
        region: '',
        stateOrProvinceGeoNameId: '',
        solutionsData: [],
        loadingSolutions: false,
        submitting: false,
        userData: {},
        checkingEmail: false,
        emailExist: true,
        disableSubscription: true,
        organizationId: '',
        showTips: false,
        tipsMessage: ''
    }
    componentDidMount() {
        this.getCountryCode();
        this.getSolutions();
        this.getCallingCode()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    getSolutions = () => {
        this.setState({ loadingSolutions: true });
        getSolutionListAsync({ Index: 1, PageSize: 999 }, { catchError: true }).then(res => {
            const { data } = res
            this.setState({ solutionsData: data.rows, loadingSolutions: false });
        }).catch(error => {
            if (error.response && error.response.status === 404) {
                this.setState({ solutionsData: [], loadingSolutions: false })
            }
        })
    };

    getCountryCode = () => {
        this.setState({ loadingCountry: true });
        GetCountryApi({}, { catchError: true }).then(res => {
            const { data } = res
            this.setState({ CountryData: data, loadingCountry: false })
        }).catch(error => {
            alertMessage.error('Get country failed')
            this.setState({ CountryData: [], loadingCountry: false })
        })
    };


    getCallingCode = () => {
        this.setState({ loadingCallingCode: true });
        GetCallingCodeApi({}, { catchError: true }).then(res => {
            const { data } = res
            this.setState({ callingCodeData: data, loadingCallingCode: false })
        }).catch(error => {
            alertMessage.error('Get calling code failed')
            this.setState({ callingCodeData: [], loadingCallingCode: false })
        })
    };

    onEmailChange = (e) => {
        e.preventDefault();
        this.setState({ userData: {}, emailExist: true, disableSubscription: true,tipsMessage: '', showTips: false }, () => {
            const { submitting, userData } = this.state;
            if (!submitting) {
                this.resetFieldsValue(userData);
            }
        });

    }


    checkEmailRegistered = debounce((rule, value, callback) => {
        const { submitting } = this.state;
        this.setState({ checkingEmail: true });
        validateEmailAsync(value, { catchError: true }).then(async (resp) => {
            if (resp.status === 200) {
                const { data } = resp;
                const { organization } = data[0];
                this.setState({ checkingEmail: false, disableSubscription: false })
                if (!submitting) {
                    this.setState({ userData: data && data[0], emailExist: true, organizationId: organization && organization.id }, () => {
                        const { userData } = this.state;
                        this.resetFieldsValue(userData);
                    });
                }
                if (organization && organization.id) {
                    const hasAMSolution = await this.checkOrganizationHasAMSolution(organization.id);
                    if (hasAMSolution) {
                        if (!submitting) {
                            this.setState({ tipsMessage: 'The user account already exists in the SFx database. You can enter additional subscriptions to the existing account below.', showTips: true })
                        }
                        callback();
                    } else {
                        callback('The email has been registered.')
                    }
                } else {
                    callback('The email has been registered.')
                }
            }
        }).catch(err => {
            if (err.response && err.response.status === 404) {
                callback();
                this.setState({ emailExist: false, checkingEmail: false, disableSubscription: false });
            }
        })
    }, 2000)

    resetFieldsValue = (userData) => {
        const { setFieldsValue } = this.props.form;
        setFieldsValue({
            surname: userData.surname,
            givenName: userData.givenName,
            mobile: {
                number: userData.mobilePhone && userData.mobilePhone.number,
                countryCodeGeoNameId: userData.mobilePhone && userData.mobilePhone.countryCodeGeoNameId,
                countryCode: userData.mobilePhone && userData.mobilePhone.countryCode
            },
            orgName: userData.organization && userData.organization.name,
            orgAddress: userData.organization && userData.organization.primaryAddress && userData.organization.primaryAddress.line1,
            orgVatId: userData.organization && userData.organization.vatId,
            orgStateOrProvince: userData.organization && userData.organization.primaryAddress && userData.organization.primaryAddress.stateOrProvinceGeoNameId,
            orgCountry: userData.organization && userData.organization.primaryAddress && userData.organization.primaryAddress.country,
            orgCity: userData.organization && userData.organization.primaryAddress && userData.organization.primaryAddress.city,
            orgZipOrPostcode: userData.organization && userData.organization.primaryAddress && userData.organization.primaryAddress.zipOrPostcode,
            [LICENSE_TIER]: '',
            licenseNumber: '',
            [ISSUE_DATE]: null,
            orgSFDCAccountId: userData.organization && userData.organization.sfdcAccountId,
            orgSFDCOpportunityId: userData.organization && userData.organization.sfdcOpportunityId


        })
    }

    checkOrganizationHasAMSolution = async (orgId) => {
        try {
            const { data } = await GetOrgSolutionsApi(orgId, { catchError: true });
            if (data && data.length) {
                return data.some(item => item.name === 'Asset Management');
            }
        } catch (error) {
            return false;
            console.log(error)
        }
    }

    checkMobile = (rule, valueObj, callback) => {
        const value = JSON.parse(valueObj);
        if (value.countryCodeGeoNameId) {
            if (value.number && value.number.trim()) {
                const result = validatePhoneNumber(value.number.trim())
                if (result.valid) {
                    callback()
                }
                else {
                    callback(result.message)
                }
            }
            else {
                callback('Phone Number is required');
            }
        }
        else {
            callback('Area code is required')
        }
    };

    checkOrganization = (rule, value, callback) => {
        if (value && value.trim()) {
            this.checkOrganizationNameUnique(value, callback);
        } else {
            callback();
        }

    }

    checkOrganizationNameUnique = debounce((value, callback) => {
        let params = {
            orgName: value.trim(),
            orgId: ''
        }
        CheckOrgApi(params, { catchError: true }).then(res => {
            const { data } = res
            if (data.length) {
                callback('This organization already exists in the SFx database,  Please confirm with your customer the SFx administrator\'s e-mail address.');
            }
        }).catch(error => {
            if (error.response && error.response.status === 404) {
                callback();
            }
        })
    }, 2000);

    checkExecutiveSalesField = (rule, value, callback) => {
        if (value && value.trim()) {
            switch (rule.field)
            {
                case "orgSFDCAccountId":
                    this.checkExecutiveSalesSFDCAccountIdUniqueness(rule.field.substring(3), value, callback);
                    break;
                case "orgSFDCOpportunityId":
                    this.checkExecutiveSalesSFDCOpportunityIdUniqueness(rule.field.substring(3), value, callback);
                    break;
                default:
                    break;
            }  
        } else {
            callback();
        }

    }

    //Executive Sales Form Field SFDCAccountId Value Uniqueness check
    checkExecutiveSalesSFDCAccountIdUniqueness = debounce((field, value, callback) => {
        let params = {
            fieldName: field,
            fieldValue: value.trim(),
            orgId: ''
        }
        CheckExecSalesFieldsApi(params, { catchError: true }).then(res => {
            const { data } = res
            if (data.length) {
                callback('This field is not unique!');
            }
        }).catch(error => {
            if (error.response && error.response.status === 404) {
                callback();
            }
        })
    }, 2000);


    //Executive Sales Form Field SFDCOpportunityId Value Uniqueness check
    checkExecutiveSalesSFDCOpportunityIdUniqueness = debounce((field, value, callback) => {
        let params = {
            fieldName: field,
            fieldValue: value.trim(),
            orgId: ''
        }
        CheckExecSalesFieldsApi(params, { catchError: true }).then(res => {
            const { data } = res
            if (data.length) {
                callback('This field is not unique!');
            }
        }).catch(error => {
            if (error.response && error.response.status === 404) {
                callback();
            }
        })
    }, 2000);

    getState = (value) => {
        this.setState({ loadingState: true })
        GetStateApi(value, { catchError: true }).then(res => {
            const { data } = res
            this.setState({ stateData: data, loadingState: false })
        }).catch(error => {
            this.setState({ stateData: [], loadingState: false })
        })
    };

    handleSelectCountryChange = (value, option) => {
        this.setState({ stateData: [], countryGeoNameId: option.key })
        let selectedCountry = this.state.CountryData.filter(country => {
            return String(country.geonameId) === String(option.key)
        })

        if (selectedCountry && selectedCountry.length === 1) {
            this.setState({
                region: selectedCountry[0].continent,
                stateOrProvinceGeoNameId: ''
            })
        }

        this.getState(option.key)
        this.props.form.setFieldsValue({
            orgStateOrProvince: '',
        });
    };

    handleSelectStateChange = (value, option) => {
        this.setState({ stateOrProvinceGeoNameId: option.key })

    };

    disableDate(current) {
        // Can not select days before today
        return current && current < moment().startOf('day');
    }

    hasErrors = (fieldsError) => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    };


    onExpiryDateChange = (value) => {
        this.setState({ subscriptionPackage: value })

    }

    onSubmit = (e) => {
        e.preventDefault();
        const { validateFields } = this.props.form;
        const { emailExist, organizationId } = this.state;
        this.setState({ submitting: true });
        validateFields(async (err, values) => {
            if (!err) {
                if (emailExist) {
                   this.submitSubscriptions(values, organizationId)
                } else {
                    const params = {
                        solutionProductCode: 'H00012562',
                        surname: values.surname,
                        givenName: values.givenName,
                        email: values.email,
                        mobile: values.mobile.number,
                        orgPhoneCountryCode: values.mobile.countryCode,
                        orgPhoneCountryCodeGeonameId: values.mobile.countryCodeGeoNameId,
                        orgName: values.orgName,
                        orgAddress: values.orgAddress,
                        orgVatId: values.orgVatId,
                        orgStateOrProvince: values.orgStateOrProvince,
                        orgStateOrProvinceId: this.state.stateOrProvinceGeoNameId,
                        orgCountry: values.orgCountry,
                        orgRegion: this.state.region,
                        orgCountryId: this.state.countryGeoNameId,
                        orgCity: values.orgCity,
                        orgZipOrPostcode: values.orgZipOrPostcode,
                        password: '',
                        orgPhoneNumber: values.mobile.number,
                        orgSFDCAccountId: (!values.orgSFDCAccountId ||  values.orgSFDCAccountId.trim() === "") ? null : values.orgSFDCAccountId,
                        orgSFDCOpportunityId: (!values.orgSFDCOpportunityId || values.orgSFDCOpportunityId.trim() === "") ? null : values.orgSFDCOpportunityId
                    }
                    try {
                        const { data } = await newOrgAndAccountAsync(params);
                        if (data) {
                           this.submitSubscriptions(values, data.organizationId)
                        }
                    } catch (err) {
                        this.setState({
                            submitting: false
                        })
                        alertMessage.error('Organization creation failed.')
                        console.log(err)
                    }
                }

            } else {
                this.setState({ submitting: false });
            }
        });
    }

    submitSubscriptions = async (values, organizationId) => {
        const IssueDate = values[ISSUE_DATE].clone().utc().format();
        let ExpiryDate = null;
        const { subscriptionPackage, solutionsData, emailExist } = this.state;
        if (subscriptionPackage === 'Pro') {
            ExpiryDate = values[ISSUE_DATE].add(1, 'years').clone().utc().format();
        } else if (subscriptionPackage === 'Pro-Trial') {
            ExpiryDate = values[ISSUE_DATE].add(45, 'days').clone().utc().format();
        }
        const solutionsDataSorted = solutionsData.length && solutionsData.sort((a, b) => {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        const filterSolutions = solutionsDataSorted.filter(i => i.name.indexOf('Asset Management') === 0);
        const defaultSolution = filterSolutions.length > 0 ? filterSolutions[0] : {};
        const subscriptionParam = new Array(values.licenseNumber).fill({
            AccountID: organizationId,
            ParentID: defaultSolution.id,
            LicenseType: 'Asset',
            [LICENSE_TIER]: subscriptionPackage,
            IssueDate,
            ExpiryDate
        });
        try {
            const { status } = await addNewLicense(subscriptionParam);
            if (status === 200) {
                this.setState({
                    submitting: false
                })
                if (emailExist) {
                    alertMessage.success('Subscriptions creation successfully')
                } else {
                    alertMessage.success('Organization creation successfully')
                }

                this.props.toggle();
            }
        } catch (err) {
            this.setState({
                submitting: false
            })
            if (emailExist) {
                alertMessage.error('Subscriptions creation failed')
            } else {
                alertMessage.error('Organization creation failed.')
            }

            console.log(err)
        }
    }

    onCloseTips = ()=>{
        this.setState({showTips:false})
    }

    render() {
        const { getFieldDecorator, isFieldsTouched, getFieldsError } = this.props.form;
        const { emailExist, checkingEmail, disableSubscription, showTips,tipsMessage,loadingCallingCode, callingCodeData, loadingState, stateData, loadingCountry, CountryData, loadingSolutions, solutionsData, submitting, showSuccess } = this.state;
        const countryDataChildren = CountryData.length && CountryData.map(i => {
            return <Option key={i.geonameId} value={i.countryName} > <><span className="mr-2" style={{ display: "inline-block", width: "35px" }} ><img src={i.flagUrl} alt={i.countryName} /></span>{i.countryName}</></Option>
        });
        const callingCodeDataChildren = callingCodeData.length && callingCodeData.map(i => {
            return <Option key={i.geonameId} value={i.geonameId.toString()} code={i.code} name={i.name}> <><span className="mr-2" style={{ display: "inline-block", width: "35px" }} ><img src={i.flagUrl} alt={i.code} /></span>(+{i.code}) {i.name}</></Option>
        });
        const stateDataChildren = stateData.length && stateData.map(i => <Option key={i.geonameId} value={i.name}> {i.name}</Option>);
        return (

            <Modal
                maskClosable={false}
                closable={true}
                title={'Add New Customer'}
                visible={true}
                bodyStyle={{ padding: '0px' }}
                onOk={this.onSubmit}
                okButtonProps={{ disabled: this.hasErrors(getFieldsError()) || !isFieldsTouched() || submitting || disableSubscription, icon: `${submitting ? 'loading' : 'check'}` }}
                width='1000px'
                onCancel={this.props.toggle}
            >
                {showTips&&<div className={styles.tips}>
                    {tipsMessage}
                    <span className={styles.closeTips} onClick={this.onCloseTips}>
                        <Icon type="close" />
                    </span>
                </div>}
                <div className={styles.wrap}>
                    <Form
                        className={styles.form}
                        layout="horizontal"
                        size={'default'}
                    >
                        <div className={styles.content} >
                            <div className={styles.PopupContainer} id='new-org-account-select-popup-container'>
                                <Row>
                                    <p className={styles.text}> {'Solution Name : HxGN SFx | Asset Management'}</p>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <Form.Item label="Email" hasFeedback>
                                            {getFieldDecorator('email', {
                                                validateFirst: true,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Enter email',
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: 'The email format is not recognized.',
                                                    },
                                                    {
                                                        validator: this.checkEmailRegistered
                                                    }
                                                ],
                                            })(<Input onChange={(e) => { this.onEmailChange(e) }} disabled={checkingEmail} />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Given Name">
                                            {getFieldDecorator('givenName', {
                                                rules: [
                                                    { required: !emailExist, message: 'Enter given name', whitespace: true },
                                                    {
                                                        max: 50,
                                                        message: 'Your first name exceed max length 50',
                                                    },
                                                ],
                                            })(<Input disabled={emailExist} />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Surname">
                                            {getFieldDecorator('surname', {
                                                rules: [
                                                    { required: !emailExist, message: 'Enter surname', whitespace: true },
                                                    {
                                                        max: 50,
                                                        message: 'Your surname exceed max length 50',
                                                    },
                                                ],
                                            })(<Input disabled={emailExist} />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Business Phone">
                                            {getFieldDecorator('mobile', {
                                                initialValue: {
                                                    number: "",
                                                    countryCodeGeoNameId: "",
                                                    countryCode: ""

                                                },
                                                rules: [
                                                    { required: !emailExist, transform: (value) => { return JSON.stringify(value) } },
                                                    { validator: !emailExist && this.checkMobile }
                                                ],
                                            })(<CallingCodePhone
                                                disabled={loadingCallingCode || emailExist}
                                                loading={loadingCallingCode}>
                                                {callingCodeDataChildren}
                                            </CallingCodePhone>)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <Form.Item label="Company" hasFeedback>
                                            {
                                                getFieldDecorator('orgName', {
                                                    rules: [
                                                        { required: !emailExist, message: 'Enter company name', whitespace: true },
                                                        { max: 50, message: 'The maximum length for organization name is 50 characters' },
                                                        { validator: !emailExist && this.checkOrganization }
                                                    ]
                                                })(<Input disabled={emailExist} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="VAT ID">
                                            {
                                                getFieldDecorator('orgVatId', {
                                                    rules: [
                                                        { max: 20, message: 'The maximum length for VAT ID is 20 characters' }
                                                    ]
                                                })(<Input disabled={emailExist} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="SFDC Account ID" hasFeedback>
                                            {
                                                getFieldDecorator('orgSFDCAccountId', {
                                                    rules: [
                                                        { max: 100, message: 'The maximum length for SDFC Account ID is 100 characters' },
                                                        { validator: !emailExist && this.checkExecutiveSalesField }
                                                    ]
                                                })(<Input disabled={emailExist} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="SFDC opportunity ID" hasFeedback>
                                            {
                                                getFieldDecorator('orgSFDCOpportunityId', {
                                                    rules: [
                                                        { max: 100, message: 'The maximum length for SFDC Opportunity ID is 100 characters' },
                                                        { validator: !emailExist && this.checkExecutiveSalesField }
                                                    ]
                                                })(<Input disabled={emailExist} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={18}>
                                        <Form.Item label="Street">
                                            {
                                                getFieldDecorator('orgAddress', {
                                                    rules: [
                                                        { required: !emailExist, message: 'Enter street address', whitespace: true },
                                                        { max: 100, message: 'The maximum length for street address is 100 characters' }
                                                    ]
                                                })(<Input disabled={emailExist} />)
                                            }
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row>

                                    <Col span={6}>
                                        <Form.Item label="City">
                                            {
                                                getFieldDecorator('orgCity', {
                                                    rules: [
                                                        { required: !emailExist, message: 'Enter city name', whitespace: true },
                                                        { max: 50, message: 'The maximum length for City is 50 characters' }
                                                    ]
                                                })(
                                                    <Input disabled={emailExist} />
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Country">
                                            {
                                                getFieldDecorator('orgCountry', {
                                                    initialValue: '',
                                                    rules: [
                                                        { required: !emailExist, message: 'Select a country' }
                                                    ]
                                                })(<Select
                                                    getPopupContainer={() => document.getElementById('new-org-account-select-popup-container')}
                                                    showSearch
                                                    onChange={this.handleSelectCountryChange}
                                                    disabled={loadingCountry || emailExist}
                                                    loading={loadingCountry}>
                                                    {countryDataChildren}
                                                </Select>)
                                            }
                                        </Form.Item>
                                    </Col>


                                    <Col span={6}>
                                        <Form.Item label="State/Province">
                                            {
                                                getFieldDecorator('orgStateOrProvince', {
                                                })(< Select
                                                    getPopupContainer={() => document.getElementById('new-org-account-select-popup-container')}
                                                    showSearch
                                                    onChange={this.handleSelectStateChange}
                                                    disabled={loadingState || emailExist}
                                                    loading={loadingState}>
                                                    {stateDataChildren}
                                                </Select>)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Zip Code/Post Code">
                                            {
                                                getFieldDecorator('orgZipOrPostcode', {
                                                    rules: [
                                                        { required: !emailExist, message: 'Enter Zip Code/Post Code', whitespace: true },
                                                        { max: 20, message: 'The maximum length for Zip Code/Post Code is 20 characters' }
                                                    ]
                                                })(<Input disabled={emailExist} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <Form.Item label="Subscription Package">
                                            {getFieldDecorator(LICENSE_TIER, {
                                                initialValue: '',
                                                rules: [{ required: !disableSubscription, message: 'Select subscription package' }],
                                            })(<Select
                                                getPopupContainer={() => document.getElementById('new-org-account-select-popup-container')}
                                                onChange={this.onExpiryDateChange}
                                                disabled={disableSubscription}
                                            >
                                                <Option value="Pro">Advanced</Option>
                                                <Option value="Pro-Trial">Advanced Trial</Option>
                                            </Select>)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label={`Subscription Number(1 - ${MAX_SUBSCRIPTION_NUMBER})`}>
                                            {getFieldDecorator('licenseNumber', {
                                                rules: [{ required: !disableSubscription, message: 'Enter subscription number' }]
                                            })(<InputNumber disabled={disableSubscription} min={1} max={MAX_SUBSCRIPTION_NUMBER} autoComplete="off" precision={0} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Planned subscription start date">
                                            {
                                                getFieldDecorator(ISSUE_DATE, {
                                                    rules: [{ type: 'object', required: !disableSubscription, message: 'Select planned subscription start date' }],
                                                })(
                                                    <DatePicker
                                                        disabled={disableSubscription}
                                                        disabledDate={this.disableDate}
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="YYYY-MM-DD HH:mm"
                                                    />
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form >
                </div></Modal>




        )
    }
}

const NewOrganizationAndAccount = Form.create({ name: 'registerOrgAndAccount' })(NewOrganizationAndAccountForm);

export default NewOrganizationAndAccount;
