import apiService from '../../services/api.service';

export const getLicenseList = (params, config = {}) => apiService.get(`/api/licenses`, { params, ...config }, { catchError: true });

export const addNewLicense = (body, options) => {
  return apiService.post('/api/licenses', body, options);
};

export const editLicense = (body, options) => {
  return apiService.put('/api/licenses', body, options);
};


export const editSelectedLicense = (body, options) => {
  return apiService.put('/api/licenses/batchupdate', body, options);
};

export const deleteLicense = (body, options) => {
  return apiService.put(`/api/licenses/delete`, body, options);
};

export const deleteSelectedLicense = (body, options) => {
  return apiService.post(`/api/licenses/deletebyids`, body, options);
}

export const getOrganization = () => {
  return apiService.get("/api/organizations");
};

export const getExpiringLicense = (remainingDays) => {
  return apiService.get(`/api/licenses/expiring/${remainingDays}`, {}, { catchError: true })
}