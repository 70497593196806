import React, { Component } from 'react';
import Loading from '../commonComponents/loading'


import { SigninCallbackComponent, SilentRedirectCallbackComponent } from '@sfx/authentication';
import userManager from '../../utils/userManage';


export class SigninCallback extends Component {

    successCallback = (user) => {
        let originalPathName = localStorage.getItem('originalPathName');
        if (originalPathName) {
            localStorage.removeItem('originalPathName');
            window.location = originalPathName;
        } else {
            window.location = '/home';
        }
    };

    errorCallback = error => {
        if (error.message && error.message === 'No matching state found in storage') {
            userManager.signIn();
        } else {
            userManager.signOut();
        }
    };

    render() {
        return (
            <SigninCallbackComponent
                userManager={userManager.userMgr}
                signinType={'redirect'}
                successCallback={this.successCallback}
                errorCallback={this.errorCallback}>
                <Loading isShowBg={true} />
            </SigninCallbackComponent>
        )
    }
}


export class SilentRedirectCallback extends Component {

    successCallback = () => {
        window.location = '/home';
    };

    errorCallback = error => {
        if (error.message && error.message === 'No matching state found in storage') {
            userManager.signIn();
        } else {
            userManager.signOut();
        }
    };

    render() {
        return (
            <SilentRedirectCallbackComponent
                userManager={userManager.userMgr}
                successCallback={this.successCallback}
                errorCallback={this.errorCallback}>
                <div>Redirecting...</div>
            </SilentRedirectCallbackComponent>
        )
    }
}

