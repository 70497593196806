import { Alert } from 'antd';
import React, { Component } from 'react';

class AlertMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        }

    }


    show() {
        this.setState({
            show: true
        });

    }

    hide() {
        this.setState({
            show: false
        });
    }


    render() {
        return (
            <div className={`aler-message-wrap ${this.state.show ? 'show' : 'hide'}`}>
                <Alert type={this.props.type} message={this.props.message} description={this.props.description} showIcon />
            </div>
        )
    }


}

export default AlertMessage;