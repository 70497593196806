import { Authentication } from '@sfx/authentication';
import { store } from '../store/reducers';
import { WebStorageStateStore } from 'oidc-client';
import AppGlobal from '../utils/util';
const authority = sessionStorage.getItem('authority');


const options = {
    // Use following setting if bad network quality
    // silentRequestTimeout :60000
    config: {
        authority: authority,
        client_id: AppGlobal.client_id,
        scope: 'openid profile email identityserver.full_access platform.full_access',
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: false,
        accessTokenExpiringNotificationTime: 180
    },
    registerEvents: {
        onUserLoaded: (user) => {
            store.dispatch({ type: 'USER_LOADED', payload: user })
        },

        onUserUnloaded: () => {
            store.dispatch({ type: 'USER_LOGOUT', payload: null });
            localStorage.clear();
        },
        
        onUserSignedOut: () => {
            store.dispatch({ type: 'USER_LOGOUT', payload: null });
            localStorage.clear();
            window.location = '/login';
        },
        onAccessTokenExpiring: () => {
            const currentUser = JSON.parse(localStorage.getItem('OIDCUSER'));
            if (currentUser && currentUser.profile && currentUser.profile.KeepSignedIn === true) {
                userManager.signinSilent();
            } else {
                userManager.signOut();
            }
        }
    },
    signinType: 'redirect'
}

const userManager = new Authentication(options);



export default userManager;
