import moment from 'moment';

export const firstLetterToUpperCase = (str) => {
    return str.replace(str.charAt(0), str.charAt(0).toUpperCase());
}

export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const isContainer = (arr1, arr2) => {
    if (Array.isArray(arr2)) {
        return arr2.some(element => {
            return arr1.indexOf(element) > -1;
        });
    } else {
        return arr1.indexOf(arr2) > -1;
    }

}

export const dateFormat = (date) => {
    if (!date) return '';
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format('YYYY-MM-DD HH:mm') : '';
}

export const dateSort = (prev, next) => {
    if (prev === next) {
        return 0;
    }
    if (!prev) {
        return -1;
    }
    if (!next) {
        return 1;
    }
    return moment(prev).valueOf() - moment(next).valueOf()
}

export const getUserConfig = () => {
    return localStorage.getItem('userConfig') ? JSON.parse(localStorage.getItem('userConfig')) : [];
};

/* 
   Append the random string to url to avoid HTML element load the resource from browser cache 
   when source url is unchanged but the file stored in cloud is updated.
*/
export const transformUrl = (url) => {
    // Pick 8 characters randomly and append them to passed in url
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let str = '';
    for (let i = 0; i < 8; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return url ? `${url}?t=${str}` : null;
}

class AppGlobal {
    constructor() {
        this.client_id = process.env.NODE_ENV === 'development' ? '1598292144' : sessionStorage.getItem('clientId');
    }
}

export const deepCopy = (obj) => {
    const target = {};
    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] && obj[key].toString() === "[object Object]" && !Array.isArray(obj[key])) {
                target[key] = deepCopy(obj[key]);
            } else {
                target[key] = obj[key];
            }
        }
    }
    return target;
}
export default (new AppGlobal())
