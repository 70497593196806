import React, { Component } from 'react';
import { Icon, Tooltip } from 'antd';
import { copyToClipboard } from '../../../utils/util';

class Menus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menus: [],
            show: false,
            title: '',
            id: '',
            tipVisibale: false
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.clickPopupMenuHandle)
    }
    clickPopupMenuHandle = e => {
        if (e.target.className !== 'popup-menu-header' && e.target.className !== 'popup-menu-header ant-tooltip-open' && e.target.className !== 'popup-title-text') {
            this.setState({
                show: false,
                tipVisibale: false
            })

        }
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.clickPopupMenuHandle);
    }
    addMenus = (content, data) => {
        this.setState({
            menus: content,
            show: true,
            title: data && data.title,
            id: data && data.id,
            titleIcon: data && data.titleIcon
        })
    }

    onMenuClick = (menu) => {
        return () => {
            menu.onClick();
        }
    }

    onClickHeader = (e) => {
        e.stopPropagation();
        this.setState({
            tipVisibale: true
        })
        this.state.id && copyToClipboard(this.state.id);
    }

    onClose = (e) => {
        e.stopPropagation();
        this.setState({
            show: false,
            tipVisibale: false
        })
    }
    render() {
        const title = (
            <div className="popup-menu-header-title">
                {this.state.titleIcon && <Icon className='popup-title-icon' type={this.state.titleIcon} />}
                <div className='popup-title-text'>{this.state.title}</div>
                <Icon className='popup-close-icon' onClick={this.onClose} type="close" />
            </div>
        )

        return (
            <div className={`popup-menu-wrap ${this.state.show ? 'show' : 'hide'}`} style={{ height: `${this.state.title ? (this.state.menus.length + 1) * 37 : this.state.menus.length * 37}px` }}>
                {this.state.title &&
                    <div className='popup-menu-header' onClick={this.onClickHeader}>
                        {this.state.id ?
                            <Tooltip placement="top" trigger={'click'} title={`${this.state.id} is Copied !`} visible={this.state.tipVisibale}>
                                <Tooltip placement="top" title={this.state.id} >
                                    {title}
                                </Tooltip>
                            </Tooltip>
                            :
                            title
                        }
                    </div>
                }
                {
                    this.state.menus.map((menu, index) => (
                        <div key={index} className='menu' onClick={this.onMenuClick(menu)}><Icon type={menu.icon} /><span className='label'>{menu.label}</span></div>
                    ))
                }
            </div>
        )
    }
}

export default Menus;