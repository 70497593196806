import { lazy } from 'react'
import { SigninCallback, SilentRedirectCallback } from '../components/callback'


const routes = [
    {
        path: '/login',
        component: lazy(() => import('../components/login'))
    },
    {
        path: '/logout',
        component: lazy(() => import('../components/logout'))
    },
    {
        path: '/callback',
        component: SigninCallback
    }, {
        path: '/silent',
        component: SilentRedirectCallback
    },
    {
        path: '/accountSettings/:activeMenu?',
        requiresAuth: true,
        main: true,
        component: lazy(() => import('../components/accountSettings')),

    },
    {
        path: '/home',
        main: true,
        requiresAuth: true,
        component: lazy(() => import('../components/home')),
        children: [
            {
                path: '/home/organization',
                requiresAuth: true,
                component: lazy(() => import('../components/organization'))
            },
            {
                path: '/home/user',
                requiresAuth: true,
                component: lazy(() => import('../components/user'))
            },
            {
                path: '/home/solution',
                requiresAuth: true,
                component: lazy(() => import('../components/solution'))
            },
            {
                path: '/home/voucherCode/:id*',
                requiresAuth: true,
                component: lazy(() => import('../components/voucherCode'))
            },
            {
                path: '/home/license/:id*',
                requiresAuth: true,
                component: lazy(() => import('../components/license'))
            },
            {
                path: '/home/banner',
                requiresAuth: true,
                component: lazy(() => import('../components/bannerManagement'))
            },
            {
                path: '/home/system/administratorManagement',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/administratorManagement'))
            },
            {
                path: '/home/system/systemLogManipulation',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/systemManagement'))
            },
            {
                path: '/home/system/auditManipulation',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/auditManipulation'))
            },
            {
                path: '/home/system/devices',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/devices'))
            },
            {
                path: '/home/system/apiResourceManagement',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/apiResourceManagement'))
            },
            {
                path: '/home/system/clientManagement',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/clientManagement'))
            },
            {
                path: '/home/system/toolset',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/toolset'))
            },
            {
                path: '/home/system/rolePrivilege',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/rolePrivilege'))
            },
            {
                path: '/home/system/landingPage',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/landingPage'))
            },
            {
                path: '/home/system/services/reporting',
                requiresAuth: true,
                role: ['SuperAdmin'],
                component: lazy(() => import('../components/reporting'))
            }
        ]
    }
]

export default routes