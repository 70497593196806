import React, { Component } from 'react';
import { Spin } from 'antd';

export default class Loading extends Component {
    render() {
        return (
            <div className={`spin-wrap ${this.props.isShowBg ? 'bg' : ''}`}>
                <Spin size='large' />
            </div>
        )

    }
}