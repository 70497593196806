import React, { Component, Suspense } from 'react';
import './styles/index.scss';
import { Route, Redirect, Switch } from 'react-router-dom';
import routes from './router/router'
import NoMatch from './components/notfound';
import { connect } from "react-redux";
import { updateUser } from './store/actions'
import Loading from './components/commonComponents/loading'
import { isContainer } from './utils/util'
import userManager from './utils/userManage'
import { cloneDeep } from 'lodash';
import Main from './components/main'

// Gets a menu with permissions
const getAuthComponent = (router, user) => {
  let role = []
  if (user && user.profile) {
    role = user.profile.role
  }
  let authRoutes = cloneDeep(router)
  authRoutes = authRoutes.filter(route => {
    let routeTemp;
    if (!route.role || (route.role && isContainer(route.role, role))) {
      if (route.children) {
        route.children = getAuthComponent(route.children, user)
        route.children.unshift({
          path: `${route.path}/`,
          requiresAuth: true,
          exact: true,
          component: route.children[0] && route.children[0]["component"]
        })
      }
      routeTemp = route
    }
    return routeTemp
  })
  return authRoutes

}
class App extends Component {
  state = {
    IsLogged: true,
    loading: true,
    authRoutes: getAuthComponent(routes, this.props.user)
  }

  componentDidMount() {
    this.checkUser()
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && (this.props.user !== prevProps.user)) {
      let authRoutes = getAuthComponent(routes, this.props.user)
      this.setState({ authRoutes })
    }
  }

  checkUser = async () => {
    let IsLogged = await userManager.checkUserAuthorized()
    this.setState({ IsLogged: IsLogged, loading: !IsLogged })
    if (!this.props.user && IsLogged) {
      userManager.getUser().then(user => {
        this.props.updateUser(user)
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={<Loading isShowBg={true} />}>
          {this.state.loading && <Loading isShowBg={true} />}
          <Switch>
            {this.state.authRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props) => {
                  if (!route.requiresAuth || this.state.IsLogged) {
                    if (route.path === '/login' && this.state.IsLogged) {
                      return <Redirect to={{ pathname: '/home' }} />
                    }
                    return <Main key={index} isMain={route.main}><route.component routes={route.children} /> </Main>
                  } else {
                    return <Redirect to={{ pathname: '/login', currentLocation: props.location.pathname }} />
                  }
                }}
              />
            ))}
            <Redirect path="/" to={{ pathname: '/home' }} />
            <Route component={NoMatch} />
          </Switch>
        </Suspense>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user
  };
}

export default connect(mapStateToProps, { updateUser })(App);