import axios from 'axios';
import { apiBaseUrl } from '../config/index';
import alertMessage from '../components/commonComponents/alertMessage';

import userManager from '../utils/userManage';

const errorMsg = {
    '401': 'Unauthorized',
    '403': 'Login expired, please login again',
    '404': 'not found',
    '500': 'Server error'
}

const handleError = (status, { catchError }) => {
    if (window.location.href.includes('/login') || window.location.href.includes('/logout')) {
        return
    }
    if (!catchError) {
        const description = errorMsg[status] || 'An unknown error'
        alertMessage.error(description);
    }
}

// get token
const AUTH = 'Authorization'


//global setting
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// axios.defaults.withCredentials = true;

const request = (URLType, url, method, params, settings) => userManager.getUser().then(user => {
    if (user) {
        axios.defaults.headers[AUTH] = 'Bearer ' + user.access_token;
        localStorage.setItem('token', user.access_token);
    }

    return apiBaseUrl(URLType).then((baseURL) => {

        return axios[method](baseURL + url, params, Object.assign({}, settings)).catch(error => {
            if (!axios.isCancel(error)) {
                // if you need to catch error, set settings value with '{catchError:true}'
                handleError(error && error.response && error.response.status, settings);
            }
            return Promise.reject(error)
        });
    });
})


const getApiService = (type) => ({
    post: (url, params, settings = {}) => {
        return request(type, url, 'post', params, settings);
    },

    get: (url, params, settings = {}) => {
        return request(type, url, 'get', params, settings);
    },

    delete: (url, params, settings = {}) => {
        return request(type, url, 'delete', params, settings);
    },

    put: (url, params, settings = {}) => {
        return request(type, url, 'put', params, settings);
    }
})

const apiService = getApiService("AuthorityUrl");


export default apiService;
