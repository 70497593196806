import { store } from '../../../store/reducers';
// Permission control at the page element level
// false -> readonly 
// [role]:{menu:[all/unusual], auth:[show component or not],[menu]:[true/false],defaultAuth:[true/false]}
const authTypeList = {
    'ReadOnly': { menu: 'all', auth: false },
    'SuperAdmin': { menu: 'all', auth: true },
    'Admin': { menu: 'unusual', solution: false, defaultAuth: true }
}
// getAuth role
const getAuth = (role) => {
    let pathname = window.location.pathname
    let currentMenu = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length)
    return authTypeList[role].menu === 'all' ?
        authTypeList[role].auth :
        (authTypeList[role][currentMenu] === undefined ?
            authTypeList[role].defaultAuth :
            authTypeList[role][currentMenu])

}
export const getMainAuth = () => {
    const { user } = store.getState()
    const currentUserRole = (user && user.user) ? user.user.profile.role : 'ReadOnly'
    if (Array.isArray(currentUserRole)) {
        // SuperAdmin > Admin > ReadOnly
        let mainRole = null
        mainRole = currentUserRole.find(i => 'SuperAdmin' === i)
        if (mainRole) {
            return getAuth(mainRole)
        }
        mainRole = currentUserRole.find(i => 'Admin' === i)
        if (mainRole) {
            return getAuth(mainRole)
        }
        mainRole = currentUserRole.find(i => 'ReadOnly' === i)
        if (mainRole) {
            return getAuth(mainRole)
        }
    } else {
        return getAuth(currentUserRole);
    }
}

