import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Switch } from 'antd';
import { withRouter } from 'react-router-dom';
import { Header, SolutionBar, UserDropdownMenu } from '@sfx/react-ui';
import { connect } from "react-redux";
import { updateSolutions } from '../../store/actions'
import { getUserConfig, isContainer } from '../../utils/util'
import { getAppFullName } from '../../config';
import { getUserSolutionsAsync } from '../solution/solution.service';
import { getUserConfigAsync, saveUserConfigAsync, getUserProfileAsync } from '../user/user.service'
import NewOrganizationAndAccount from '../newOrganizationAndAccount'
import NewVouchers from '../newVouchers';

class HeaderComponent extends Component {

    state = {
        AppFullName: "Admin Site",
        orgName: '',
        givenName: '',
        familyName: '',
        role: '',
        openSolutionBar: false,
        userConfig: getUserConfig(),
        activeMenu: '',
        userConfigVisible: false,
        solutions: [],
        showCreateCustomer: false,
        showCreateVouchers: false
    }

    componentDidMount() {
        getAppFullName().then(fullName => {
            if (fullName) {
                this.setState({ AppFullName: fullName });
            }
        });

        if (this.props.user) {
            this.getSolutions()
            this.getUserConfig()
            this.getUserProfile()
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.user && (this.props.user !== prevProps.user)) {
            this.getUserProfile()
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    getSolutions = async () => {
        try {
            const { data } = await getUserSolutionsAsync(this.props.user.profile.key);

            const solutions = data.map(r => {
                let slnIcon = r.images && r.images[0];
                let defaultIconUrl = r.defaultImageUrl;
                let solutionIconUrl = (slnIcon && slnIcon.url) ? slnIcon.url : defaultIconUrl;

                return {
                    url: r.clientUri ? r.clientUri : '/',
                    id: r.id,
                    name: r.name,
                    fullName: r.fullName,
                    shortName: r.shortName,
                    img: solutionIconUrl,
                    thumbnail: r.thumbnailUrl
                };
            });
            this.setState({ solutions })
            this.props.updateSolutions(solutions)
        } catch (err) {

        }

    }

    getUserProfile = async () => {
        try {
            const { profile } = this.props.user
            const { data } = await getUserProfileAsync(profile.key)
            const { avatar, givenName, surname } = data

            let avatarUrl = avatar && avatar.url
            this.setState({ givenName, familyName: surname, avatarUrl, role: profile.role, orgName: profile.OrgName })
        } catch (error) {
            const givenName = this.props.user && this.props.user.profile && this.props.user.profile.given_name;
            const surname = this.props.user && this.props.user.profile && this.props.user.profile.family_name;
            this.setState({ givenName, familyName: surname, avatarUrl: '' })
        }
    }

    triggerSolutionBar = () => {
        this.setState({ openSolutionBar: !this.state.openSolutionBar })
    };




    getUserConfig = async () => {
        try {
            const { data } = await getUserConfigAsync()
            localStorage.setItem('userConfig', JSON.stringify(data))
            this.setState({ userConfig: data })
        } catch (error) {
            this.setState({ userConfig: [] })
        }
    }

    updateUserConfig = async (checked, e, name) => {
        let { userConfig } = this.state
        userConfig = userConfig.map(i => {
            if (i.name === name) {
                i.value = String(checked)
            }
            return i
        })

        this.props.onSwitch(true);
        try {
            await saveUserConfigAsync(userConfig)
            this.props.onSwitch(false);
            this.setState({ userConfig, userConfigVisible: false })
            localStorage.setItem('userConfig', JSON.stringify(userConfig))
        } catch (error) {
            userConfig = userConfig.map(i => {
                if (i.name === name) {
                    i.value = String(!checked) //reset value

                }
                return i
            })
            this.props.onSwitch(false);
            this.setState({ userConfig, userConfigVisible: false })
            localStorage.setItem('userConfig', JSON.stringify(userConfig))
        }
    }


    toggleCreateCustomer = () => {
        this.setState({ showCreateCustomer: !this.state.showCreateCustomer })
    }

    toggleCreateVouchers = () => {
        this.setState({ showCreateVouchers: !this.state.showCreateVouchers })
    }

    render() {
        let role = [];
        if (Array.isArray(this.state.role)) {
            role = this.state.role.map(r => {
                return r.toLowerCase()
            });
        } else {
            role = [this.state.role.toLowerCase()]
        }
        const canCreateCustomerRoles = ['superadmin', 'admin', 'salesexecutive'];
        const canCreateCustomer = isContainer(canCreateCustomerRoles, role);

        const dropDownMenuOptions = {
            role: this.state.role,
            avatarUrl: this.state.avatarUrl,
            givenName: this.state.givenName,
            familyName: this.state.familyName,
            showCamera: true,
            onSignOut: () => { this.props.history.push('/logout'); },
            onCamera: () => { }
        };

        const menuItems = [
            // {
            //     name: 'New Customer',
            //     onClick: () => {
            //         this.setState({ showCreateCustomer: true })
            //     }
            // },
            {
                name: 'New Vouchers',
                onClick: () => {
                    this.setState({ showCreateVouchers: true })
                }
            }
        ]

        const footerMenu = {
            accountSetting: {
                label: 'Account Settings',
                onClick: () => {
                    this.props.history.push('/accountSettings')

                }
            }
        }

        const solutionBarOptions = {
            open: this.state.openSolutionBar,
            solutions: this.state.solutions,
            language: "en",
            onClose: this.triggerSolutionBar,
            footerMenu
        };

        const solutionBar = (
            this.state.openSolutionBar && <SolutionBar {...solutionBarOptions} />
        );

        const settingMenu = (
            <Menu className={'header-setting-dropdown'} onClick={this.onMenuClick}>
                {
                    this.state.userConfig.map((config, index) => (
                        <Menu.Item key={config.name}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: "row", justifyContent: 'space-between' }}>
                                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>{config.displayName}</span>
                                <Switch size='small' defaultChecked={config.value && config.value.toLowerCase() === 'true'} onChange={(checked, e, name) => this.updateUserConfig(checked, e, config.name)} />
                            </div>
                            <div style={{ marginTop: '10px', whiteSpace: 'pre-line' }}>
                                {config.description}
                            </div>
                            {(index < this.state.userConfig.length - 1) && <hr style={{ border: '0.5px solid', marginTop: '20px', marginBottom: '10px' }} />}
                        </Menu.Item>
                    )
                    )
                }
            </Menu>
        );


        const rightContent = (
            <div>
                { (Array.isArray(this.state.role) || (this.state.role && this.state.role.toLowerCase() !== 'salesexecutive')) && 
                    <div className={`header-menu-wrap ${this.state.userConfigVisible ? 'active' : ''}`}>
                        <div className={`header-menu-box `}
                            onClick={() => this.setState({ activeMenu: 'userConfig' })}>
                            {!!this.state.userConfig.length &&
                                <Dropdown
                                    trigger={['click']}
                                    className='dropdown-title'
                                    overlay={settingMenu}
                                    onVisibleChange={flag => { this.setState({ userConfigVisible: flag }) }}
                                    visible={this.state.userConfigVisible}>
                                    <span className="ant-dropdown-link" >
                                        <Icon className='setting-icon' type="setting" />
                                    </span>
                                </Dropdown>
                            }

                        </div>
                    </div>
                }
                <UserDropdownMenu {...dropDownMenuOptions} menuItems={canCreateCustomer ? menuItems : []} />
            </div>

        );



        return (
            <>
                <Header
                    loggedIn={true}
                    orgName={this.state.orgName}
                    shortTitle={"AS"}
                    title={`HxGN SFx | ${this.state.AppFullName}`}
                    isShowSolutionBar={true}
                    onSolutionMenuButton={() => {
                        this.triggerSolutionBar();
                    }}
                    solutionBar={solutionBar}
                    rightContent={rightContent}
                />
                {/* {canCreateCustomer && this.state.showCreateCustomer && <NewOrganizationAndAccount
                    toggle={this.toggleCreateCustomer} />} */}
                 {canCreateCustomer && this.state.showCreateVouchers && <NewVouchers
                    toggle={this.toggleCreateVouchers} />}    
            </>
        )

    }

}

function mapStateToProps(state) {
    return {
        user: state.user.user
    };
}
export default connect(mapStateToProps, { updateSolutions })(withRouter(HeaderComponent));