import apiService from '../../services/api.service'
import queryString from 'query-string'


export const getSolutionTypeAsync = (body, options) => {
    return apiService.get('/api/solutions/types', body, options);
}

export const getUserSolutionsAsync = (userId, options) => {
    return apiService.get(`/api/user/self/${userId}/solutions`, null, options);
}

export const createSolutionAsync = (body, options) => {
    return apiService.post('/api/solutions', body, options);
}

export const updateSolutionBasicInfoAsync = (body, options) => {
    return apiService.put('/api/solutions', body, options);
}

export const deleteSolutionAsync = (solutionId) => {
    return apiService.delete(`/api/solutions/${solutionId}`)
}

export const getSolutionListAsync = (params, options) => {
    //NOTE: The apiService has an issue with get method, need to set the opts twice here to make the catchError and request config both working.
    return apiService.get('/api/solutions', { params, ...options }, options);
}

export const createRoleAsync = (solutionId, body, options) => {
    return apiService.post(`/api/solutions/${solutionId}/roles`, body, options);
}

export const deleteRoleAsync = (roleId, body, options) => {
    return apiService.delete(`/api/solutions/roles/${roleId}`, body, options);
}

export const validateRoleAsync = (solutionId, roleName, opts) => {
    return apiService.get(`/api/solutions/${solutionId}/roles/${roleName}`, null, opts);
}

export const updateRoleAsync = (solutionId, body, opts) => {
    return apiService.put(`/api/solutions/${solutionId}/roles`, body, opts);
}

export const sortRoleAsync = (solutionId, body, opts) => {
    return apiService.put(`/api/solutions/${solutionId}/roles/order`, body, opts);
}

export const validateSolutionNameAsync = (solutionName, opts) => {
    return apiService.get(`/api/solutions/name/${solutionName}`, null, opts);
}

export const checkSolutionByClientIdAsync = (clientId, opts) => {
    return apiService.get(`/api/solutions/check/${clientId}`, null, opts);
}
export const getSolutionByClientIdAsync = (clientId, options) => {
    return apiService.get(`/api/solutions/id/${clientId}`, { clientId }, options);
}
export const uploadFilesAsync = (params, opts) => {
    return apiService.post(`/api/solutions/UploadImage/${params.solutionId}`, params.file);
}
export const deleteFilesAsync = (imgageId, opts) => {
    return apiService.delete(`/api/solutions/image/${imgageId}`, opts);
}
export const getSolutionDefaultImgAsync = (opts) => {
    return apiService.get(`/api/solutions/defaultimage`, null, opts);
}
export const getOrgUserGroupsAsync = (solutionId, opts) => {
    return apiService.get(`/api/solutions/${solutionId}/users`, null, opts);
}
export const getOrgListAsync = (solutionId, opts) => {
    return apiService.get(`/api/solutions/${solutionId}/organizations`, null, opts);
}
export const getUsersByAsync = (params = {}) => {
    return apiService.get(`/api/users?${queryString.stringify(params)}`)
}
export const mapUserRoleAsync = (body, options) => {
    return apiService.post('/api/users/slnRoles', body, options);
}

export const checkProductCodeAvailabilityAsync = (productCode, solutionId, options) => {
    return apiService.get(`/api/solutions/check/code/${productCode}/${solutionId ? solutionId : ''}`, null, options);
}

export const getSubscriptionTypeListAsync = (options) => {
    return apiService.get(`/api/system/subscriptiontypes`, null, options);
}

export const getUnassignedOrganizationUsersForSolution = (solutionId, organizationId, opts) => {
    return apiService.get(`/api/solutions/${solutionId}/${organizationId}/users/noaccess`, null, opts);
};
export const uploadThumbnailAsync = (slnId, params, opts) => {
    return apiService.post(`/api/solutions/${slnId}/thumbnail`, params);
}
export const removeThumbnailAsync = (slnId, opts) => {
    return apiService.delete(`/api/solutions/${slnId}/thumbnail`, opts);
}

export const getAssignedApiResourcesAsync = (slnId, opts) => {
    return apiService.get(`/api/solutions/${slnId}/apiresources`, opts);
};

export const getUnassignedApiResourcesAsync = (slnId, opts) => {
    return apiService.get(`/api/solutions/${slnId}/apiresources/unassigned`, opts);
};

export const assignScopesToSolutionAsync = (slnId, scopes, opts) => {
    return apiService.put(`/api/solutions/${slnId}/assign/scopes`, scopes, opts);
};

export const unlinkScopesAndSolutionAsync = (slnId, scopes, opts) => {
    return apiService.put(`/api/solutions/${slnId}/unlink/scopes`, scopes, opts);
};