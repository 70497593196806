import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer from './userReducer';
import solutionsReducer from './solutionsReducer'


const rootPersistConfig = {
    key: 'root',
    storage: storage
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    user: userReducer,
    solutions: solutionsReducer
});

const pReducer = persistReducer(rootPersistConfig, rootReducer)


export const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);


