import React, { Component } from 'react';
import axios from 'axios';
import FixedHeaderTable from '../commonComponents/table';
import { getVoucherCodes } from "../voucherCode/voucherCode.service";
import { Form, Modal, Row, Col, Input, Pagination, Tooltip, Select, Icon, Button } from 'antd';
import moment from 'moment'
import OrgTreeSelectNexus from '../user/orgTreeSelectNexus.component';
import { getUserConfig } from '../../utils/util';
import { copyToClipboard, firstLetterToUpperCase } from '../../utils/util';
import { getSolutionListAsync } from '../solution/solution.service';
import MultiSerialNumbers from './multiSerialNumbersInput';
import { CSVLink } from "react-csv";
import alertMessage from '../commonComponents/alertMessage';
import styles from './newVouchers.module.scss'

const CancelToken = axios.CancelToken;
const Search = Input.Search;
const { Option } = Select;

class ViewVouchersForm extends Component {
    columns = [
        {
            title: 'Voucher Code',
            dataIndex: 'code',
            key: 'code',
            width: '22%',
            sorter: false,
            render: text => (
                <Tooltip placement="right" trigger={'click'} title={`${text} is copied!`}>
                    <div className={styles.pointerCursor} onClick={this.clickToCopy(text)}>{text}</div>
                </Tooltip>)
        },
        {
            title: 'Associated Machine Serial Number',
            dataIndex: 'machineSerialNumber',
            key: 'machineSerialNumber',
            width: '20%',
            sorter: false,
            render: (text) => {
                let html = 
                <Tooltip placement="right" trigger={'click'} title={`${text} is copied!`}>
                    <span style={{ whiteSpace: 'pre-wrap' }} className={styles.pointerCursor} onClick={this.clickToCopy(text)}>
                        {text}
                    </span>
                </Tooltip>
                return text ? html : '--'
            }
        },
        {
            title: 'Creation Date',
            width: '16%',
            sorter: true,
            key: 'createdDate',
            dataIndex: 'createdDate',
            render: (text) => {
                let html = <span>
                    {text && moment.utc(text).local().format('YYYY-MM-DD HH:mm:ss')}
                </span>
                return text ? html : '--'
            }
        },
        {
            title: 'Subscription Duration',
            dataIndex: 'subscriptionDuration',
            key: 'subscriptionDuration',
            width: '10%',
            sorter: false,
            render: (text, record) => {
                let displayTest = '--';
                switch(text) {
                    case '45d':
                        displayTest = '45 Days';
                        break;
                    case '1y':
                        displayTest = '1 Year';
                        break;
                    case '2y':
                        displayTest = '2 Years';
                        break;
                    case '3y':
                        displayTest = '3 Years';
                        break;
                    default:
                        displayTest = '--';
                        break;
                }

                return <div>{displayTest}</div>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            sorter: false,
            render: (text, record) => {
                return <div>{text}</div>
            }
        },
        {
            title: 'Date consumed/Date expired',
            width: '16%',
            sorter: false,
            key: 'expiredDate',
            dataIndex: 'expiredDate',
            render: (text, record) => {
                if (record.status === 'Consumed') { }
                text = record.status === 'Consumed' ? record.consumedDate : record.expiredDate
                let html = <span>
                    {text && moment.utc(text).local().format('YYYY-MM-DD HH:mm:ss')}
                </span>
                return text ? html : '--'
            }
        }
    ];

    state = {
        tableData: [],
        csvData: [],
        total: 0,
        loadingTable: false,
        loadingExport: false,
        pageSize: 10,
        pageIndex: 1,
        orderBy: '',
        direction: '',
        searchText: '',
        loadingSolutionList: false,
        solutionList: [],
        rowId: '',
        orgId: '',
        selectedOrgId: '',
        selectedSlnId: '',
        multi: false,
        machineSerialNumbers: [],
        serialNumberValue:''
    };

    getVoucherCodesCancelSource = CancelToken.source();
    fetchVoucherCodes = (orgId, id) => {
        this.getVoucherCodesCancelSource.cancel();
        this.getVoucherCodesCancelSource = CancelToken.source();
        const params = {
            OrganizationId: id || this.state.orgId || '',
            NexusAccountID: orgId || this.state.selectedOrgId || '',
            SolutionId: this.state.selectedSlnId ? this.state.selectedSlnId : '',
            Code: this.state.searchText,
            SerialNumber: this.state.machineSerialNumbers,
            Size: this.state.pageSize,
            Index: this.state.pageIndex,
            OrderBy: this.state.orderBy && firstLetterToUpperCase(this.state.orderBy),
            Direction: this.state.direction && this.state.direction.substring(0, this.state.direction.length - 3)
        };
        this.setState({
            loadingTable: true
        })

        getVoucherCodes(params, { cancelToken: this.getVoucherCodesCancelSource.token })
            .then((response) => {
                if (response && response.data && response.data.rows) {
                    const formatData = this.formatTableData(response.data.rows)
                    this.setState({
                        tableData: formatData,
                        loadingTable: false,
                        total: response.data.total
                    })
                }
            }).catch(error => {
                if (axios.isCancel(error)) { return; }
                this.setState({
                    tableData: [],
                    loadingTable: false,
                    total: 0
                });
            })
    }

    formatTableData = (data) => data.map(item => ({
        key: item.id,
        ...item
    }))

    getSolutionList = () => {
        this.setState({
            loadingSolutionList: true
        })
        getSolutionListAsync({
                SearchText: 'Asset Management',
                PageSize: 100,
                Index: 1
        }, { catchError: true }).then(res => {
            this.setState({
                loadingSolutionList: false,
                solutionList: res.data.rows
            })
        }).catch(() => {
            this.setState({
                loadingSolutionList: false
            })
        })
    }

    componentDidMount() {
        this.fetchVoucherCodes()
        this.getSolutionList();
    }

    componentWillUnmount() {
        this.timer && clearTimeout(this.timer)
    }

    clickToCopy = (key) => {
        return (e) => {
            e.stopPropagation();
            this.setState({
                rowId: ''
            });
            copyToClipboard(key);
        }
    }

    // pagination
    onPageChange = (page, pageSize) => {
        this.setState({
            pageIndex: page
        }, () => {
            this.fetchVoucherCodes(this.state.selectedOrgId, this.state.orgId);
        })
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            rowId: '',
            pageIndex: current,
            pageSize: pageSize
        }, () => {
            this.fetchVoucherCodes(this.state.selectedOrgId, this.state.orgId);
        })
    }

    setSelectedOrgId = (combinedOrgId, name) => {
        let orgId = '';
        let selectedOrgId = '';
        if (combinedOrgId) {
            orgId = combinedOrgId.split("|")[0];
            selectedOrgId = combinedOrgId.split("|")[1];
        }

        this.setState({
            orgId,
            selectedOrgId
        }, () => {
            this.setState({
                pageIndex: 1
            }, () => {
                this.fetchVoucherCodes(selectedOrgId, orgId);
            })
        })
    }

    onSolutionChange = (value) => {
        this.setState({
            selectedSlnId: value,
            pageIndex: 1
        }, () => {
            this.fetchVoucherCodes(this.state.selectedOrgId, this.state.orgId);
        });

    }

    onSearch = (value) => {
        this.setState({
            searchText: value,
            pageIndex: 1
        }, () => {
            this.fetchVoucherCodes(this.state.selectedOrgId, this.state.orgId);
        })

    }

    onSearchSN = (value) => {
        this.setState({
            pageIndex: 1,
            machineSerialNumbers: value
        }, () => {
            this.fetchVoucherCodes(this.state.selectedOrgId, this.state.orgId);
        })
    }

    onChangeVoucherList = (pagination, filters, sorter) => {
        this.setState({
            orderBy: sorter.field,
            direction: sorter.order
        }, () => {
            this.fetchVoucherCodes(this.state.selectedOrgId, this.state.orgId);
        })
    }

    filterOption = (input, option) => {
        let userConfig = getUserConfig();
        let caseSensitiveSearch = userConfig.find(config => config.name === 'CaseSensitiveSwitch').value.toLowerCase() === true.toString().toLowerCase();
        if (caseSensitiveSearch) {
            return option.props.children.indexOf(input) >= 0;
        } else {
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
    };

    toggleMulti = () => {
        this.setState({
            multi: !this.state.multi
        });
    };

    prepareCsvData = (data) => data.map(item => ({
        'Voucher Code': item.code,
        'Associated Machine Serial Number': item.machineSerialNumber,
        'Creation Date': moment.utc(item.createdDate).local().format('YYYY-MM-DD HH:mm:ss') + '\t',
        'Creator': item.creator ? item.creator.givenName + ' ' + item.creator.surname : '',
        'Expiry Date': moment.utc(item.expiredDate).local().format('YYYY-MM-DD HH:mm:ss') + '\t',
        'Subscription Duration': this.formatSubscriptionDuration(item),
        'Status': item.status,
        'Solution': 'Metrology Asset Manager'
    }))

    formatSubscriptionDuration = (voucher) => {
        let subscriptionDuration = '';
        if (voucher) {
            switch (voucher.subscriptionDuration) {
                case '45d':
                    subscriptionDuration = '45 Days';
                    break;
                case '1y':
                    subscriptionDuration = '1 Year';
                    break;
                case '2y':
                    subscriptionDuration = '2 Years';
                    break;
                case '3y':
                    subscriptionDuration = '3 Years';
                    break; 
                default:
                    subscriptionDuration = '';
                    break;
            }
        }
        return subscriptionDuration;
    }

    composeCsvFileName = () => {
        if (this.state.csvData.length === 1) {
            return 'Voucher Metrology Asset Manager ' + this.state.csvData[0]['Associated Machine Serial Number'] + ' ' + moment.utc().local().format('YYYY-MM-DD HH_mm_ss') + '.csv'
        } else if (this.state.csvData.length > 1) {
            return 'Vouchers Metrology Asset Manager (' + this.state.csvData.length + ') ' + moment.utc().local().format('YYYY-MM-DD HH_mm_ss') + '.csv';
        }
    }

    exportCSV = (event) => {
        alertMessage.info('Preparing CSV data...Please wait');
        this.setState({
            csvData: []
        }, () => {
            let csvData = [];

            const params = {
                NexusAccountID: this.state.selectedOrgId,
                OrganizationId: this.state.orgId || '',
                SolutionId: this.state.selectedSlnId,
                Code: this.state.searchText,
                SerialNumber: this.state.machineSerialNumbers,
                Size: 0,
                Index: this.state.pageIndex,
                OrderBy: this.state.orderBy && firstLetterToUpperCase(this.state.orderBy),
                Direction: this.state.direction && this.state.direction.substring(0, this.state.direction.length - 3)
            };
            this.setState({
                loadingExport: true
            })

            getVoucherCodes(params)
                .then((response) => {
                    if (response && response.data && response.data.rows) {
                        csvData = this.prepareCsvData(response.data.rows);
                        this.setState({ csvData, loadingExport: false }, () => {
                            this.timer = setTimeout(() => {
                                // Ensure CSV Link retrieve the correct data in React state
                                this.csvLink.link.click();
                            }, 100);
                        })
                    }
                }).catch(error => {
                    this.setState({
                        loadingExport: false,
                    });
                })
        })
    }

    render() {
        const { selectedOrgId, orgId, loadingSolutionList, solutionList, loadingTable, loadingExport, csvData } = this.state;
        const accountIDs = selectedOrgId && orgId ? `${orgId}|${selectedOrgId}` : orgId ?  `${orgId}|`: null;
        return (
            <Modal
                maskClosable={false}
                closable={true}
                title={'View Vouchers'}
                visible={true}
                bodyStyle={{ padding: '10px' }}
                footer={null}
                width='90%'
                onCancel={this.props.toggle}
                
            >
            <div className='main-container-wrap'>
                <Row gutter={24} type="flex" justify="end" align="bottom">
                    <Col span={2}>
                    <CSVLink
                            data={csvData}
                            filename={this.composeCsvFileName()}
                            hidden
                            ref={(r) => this.csvLink = r}
                            target="_blank"
                        >
                            <Icon type='export'></Icon>
                            <span>CSV</span>
                        </CSVLink>
                        <Button loading={loadingExport} style={{ marginRight: '10px' }} onClick={this.exportCSV} disabled={loadingTable || loadingExport} icon='export' className="ant-btn ant-btn-primary">CSV</Button>
                    </Col>
                    <Col span={7}>
                        <OrgTreeSelectNexus
                            setSelectedOrgId={this.setSelectedOrgId}
                            defaultOrgId={accountIDs}
                            userConfig={getUserConfig()}>
                        </OrgTreeSelectNexus>
                    </Col>
                    <Col span={5}>
                        <Select optionFilterProp='children'
                            showSearch
                            placeholder="Select Solution"
                            disabled={loadingSolutionList}
                            allowClear
                            style={{ width: '100%' }}
                            onChange={this.onSolutionChange}
                            filterOption={this.filterOption}>
                            {
                                solutionList.map(item => <Option key={item.id} value={item.id}>{item.name === 'Asset Management' ? 'Metrology Asset Manager' : item.name}</Option>)
                            }
                        </Select>
                    </Col>
                    <Col span={5}>
                        <Search allowClear onSearch={this.onSearch} placeholder="Voucher Code" />
                    </Col>
                    <Col span={5}>
                        {this.state.multi && <MultiSerialNumbers 
                                                toggle={this.toggleMulti}
                                                onSubmit={this.onSearchSN}
                                                preValue={this.state.machineSerialNumbers}/>}
                        <Input type='text' onClick={this.toggleMulti} placeholder="Machine Serial Number" />
                    </Col>
                </Row>
                <br />
                <FixedHeaderTable
                    scroll={{y:'calc(100vh - 320px)'}}
                    size='middle'
                    loading={loadingTable}
                    pagination={false}
                    onChange={this.onChangeVoucherList}
                    rowClassName={this.setRowClassName}
                    columns={this.columns} dataSource={this.state.tableData} />
                <br />

                {
                    this.state.total !== 0 && <Pagination
                        showSizeChanger
                        onChange={this.onPageChange}
                        current={this.state.pageIndex}
                        onShowSizeChange={this.onShowSizeChange}
                        total={this.state.total}
                        showTotal={total => `Total ${this.state.total} items`} />
                }
            </div>
            </Modal>
        )
    }
}

const ViewVouchers = Form.create({ name: 'newVouchersCode' })(ViewVouchersForm);
export default ViewVouchers;