import React, { Component } from 'react';
import { Layout } from 'antd';
import Header from '../header';
import Footer from '../footer';

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            forceRefresh: false,
        };
    }

    checkMainComponent = arr => {
        try {
            return arr.filter(item => item.main)
        } catch (error) {
            return []
        }

    }
    onSwitch = (forceRefresh) => {
        this.setState({ forceRefresh });

    }
    render() {
        return (
            this.props.isMain ?
                <>
                    <Layout className='layout'>
                        <div>
                            <Header onSwitch={this.onSwitch} />
                        </div>
                        <Layout className='middle'>
                            {this.props.children}
                        </Layout>
                    </Layout >
                    <Footer />
                </>
                : this.props.children
        )
    }
}



export default Main;