import apiService from '../../services/api.service'
import queryString from 'query-string'

export const validateEmailAsync = (email, opts) => {
    return apiService.get(`/api/users/${email}`, {}, opts)
}

export const validateOrgNameAsync = (orgName, opts) => {
    return apiService.get(`/api/organizations/${orgName}`, {}, opts)
}

export const getCountryListAsync = () => {
    return apiService.get('/api/geo/admin/country')
}

export const getStateListAsync = (countryId) => {
    return apiService.get(`/api/geo/admin/${countryId}/state`)
}

export const addUserAsync = (body, opts) => {
    return apiService.post('/api/users', body, opts)
}

export const updateUserAsync = (body, opts) => {
    return apiService.put('/api/users', body, opts)
}

export const deleteUserAsync = (idOrEmail) => {
    return apiService.delete(`/api/users/${idOrEmail}`)
}

export const disableUserAsync = (idOrEmail) => {
    return apiService.put(`/api/users/${idOrEmail}/disable`)
}

export const unlockUserAsync = (userId) => {
    return apiService.post(`/api/users/unlock/${userId}`)
}

export const setUserOrgLinkAsync = (body) => {
    return apiService.put(`/api/organizations/users`, body)
}

export const confirmEmailAsync = (body) => {
    return apiService.post('/api/users/confirmEmail', body)
}
export const GetCallingCodeApi = (params, opts) => {
    return apiService.get(`/api/geo/admin/callingcodes`, params, opts);
};

export const getOrganizationTreeAsync = () => {
    return apiService.get('/api/organizations/tree')
}

export const getNexusOrganizationTreeAsync = () => {
    return apiService.get('/api/organizations/nexus/tree')
}

export const getUsersByAsync = (params = {}, config = {}) => {
    return apiService.get(`/api/users?${queryString.stringify(params)}`, config)
}

export const getUsersByIdOrEmailAsync = (idOrEmail) => {
    return apiService.get(`/api/users/${idOrEmail}`)
}

export const getSolutionsByOrgIdAsync = (orgId) => {
    return apiService.get(`/api/organizations/${orgId}/solutions`, {}, { catchError: true })
}

export const getUserRolesAsync = (userIdOrEmail) => {
    return apiService.get(`/api/users/slnRoles/${userIdOrEmail}`, {}, { catchError: true });
}

export const assignRolesToUserAsync = (roleAssignment = { userId: '', roleIdsToBeAdded: [''], roleIdsToBeDeleted: [''] }) => {
    return apiService.put(`/api/users/roles`, roleAssignment)
}

export const deleteSolutionAsync = (slnId) => {
    return apiService.delete(`/api/solutions/${slnId}`)
}
export const getLastSignInHistoryAsync = (params) => {
    return apiService.post(`/api/users/signin/${params.id}`, params.body, { catchError: true })
}

export const getUserConfigAsync = () => {
    return apiService.get(`/api/user/self/config`, {}, { catchError: true })
}
export const saveUserConfigAsync = (params) => {
    return apiService.post(`/api/user/self/config`, params, { catchError: true })
}
export const getUserProfileAsync = userId => {
    return apiService.get(`/api/user/self/${userId}/profiles`, {}, { catchError: true })
}
export const getSFxRolesAsync = () => {
    return apiService.get(`/api/sfx/roles`, {}, { catchError: true })
}
export const getAllSubscriptionOptionsAsync = (orgId) => {
    return apiService.get(`/api/licenses/${orgId}`, {}, { catchError: true })
}

export const newOrgAndAccountAsync = (params) => {
    return apiService.post(`/api/webstore/user/self`, params, { catchError: true })
}