export const regions = [
    {
        fullName: 'Africa',
        shortName: 'AF'
    },
    {
        fullName: 'Antarctica',
        shortName: 'AN'
    },
    {
        fullName: 'Asia',
        shortName: 'AS'
    },
    {
        fullName: 'Europe',
        shortName: 'EU'
    },
    {
        fullName: 'North America',
        shortName: 'NA'
    },
    {
        fullName: 'Oceania',
        shortName: 'OC'
    },
    {
        fullName: 'South America',
        shortName: 'SA'
    }
];

export const getRegionFullNameByShortName = shortName => {
    var region = regions.find(reg => reg.shortName === shortName);

    return region ? region.fullName : "";
};
