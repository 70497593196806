import React, { Component } from 'react';
import { TreeSelect, Row, Col, Select } from 'antd';

import { getNexusOrganizationTreeAsync } from './user.service';
import { getRegionFullNameByShortName, regions } from '../../utils/regions';

export default class orgTreeSelectNexus extends Component {
  state = {
    rawData: [],
    treeData: [],
    Region: undefined,
    loadingOrg: true,
    defaultOrgId: undefined,
    allRegions: [],
    multiple: false
  };

  traverse = item => {
    let node = {
      title: `${item.name} ${
        getRegionFullNameByShortName(item.targetRegion)
        ? '(' + getRegionFullNameByShortName(item.targetRegion) + ')'
        : item.primaryAddress && item.primaryAddress.region && getRegionFullNameByShortName(item.primaryAddress.region) ?
        '(' + getRegionFullNameByShortName(item.primaryAddress.region)+ ')' : ''
      }`,
      key1: item.id,
      value: `${item.id}|${
        item.auth0OrgId
          ? item.auth0OrgId
          : ''
      }`,
      key: `${item.id}|${
        item.auth0OrgId
          ? item.auth0OrgId
          : ''
      }`
    };
    return node;
  };

  componentDidMount() {
    this.setState({ allRegions: regions });
    if (this.props.defaultOrgId && !this.state.defaultOrgId) {
      this.setState({ defaultOrgId: this.props.defaultOrgId });
    }
    if (this.props.Region && !this.state.Region) {
      this.setState({ Region: this.props.Region });
    }

    this.initOrgTree();
  }

  findRegionByOrganizationId = (orgId, orgs) => {
    let accountId = orgId.split('|')[0] || '';
    let nexusAccountID = orgId.split('|')[1];
    let org = orgs.find(x => (nexusAccountID ? x.auth0OrgId === nexusAccountID : !x.auth0OrgId) &&
      x.id === accountId);
    if (org) {
      if (org.targetRegion) {
        return org.targetRegion;
      } else if (org.primaryAddress && org.primaryAddress.region) {
        return org.primaryAddress.region;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  initOrgTree = async () => {
    try {
      let { data = [] } = await getNexusOrganizationTreeAsync();

      let treeData = data.map(item => {
        return this.traverse(item);
      });

      this.setState({
        treeData: treeData,
        rawData: data,
        loadingOrg: false
      });

      if (this.state.Region) {
        this.filterOrgDataByRegion(this.state.Region);
      } else if (this.state.defaultOrgId) {
        let targetRegion = this.findRegionByOrganizationId(
          this.state.defaultOrgId,
          data
        );
        this.filterOrgDataByRegion(targetRegion);
        this.setState({ Region: targetRegion });
      }

      this.setState({ loadingOrg: false });
    } catch (err) {
      console.log(err);
    }
  };
  onChange = (orgId, option) => {
    this.setState({ defaultOrgId: orgId });
    if (this.props.setSelectedOrgId) {
      this.props.setSelectedOrgId(orgId, option[0]);
    }

    if (this.props.getUserList) {
      this.props.getUserList({
        orgId,
        index: 1
      });
    }
  };

  filterOption = (input, treeNode) => {
    let caseSensitiveSearch =
      this.props.userConfig
        .find(config => config.name === 'CaseSensitiveSwitch')
        .value.toLowerCase() === true.toString().toLowerCase();
    if (caseSensitiveSearch) {
      return treeNode.props.title.indexOf(input) >= 0;
    } else {
      return (
        treeNode.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }
  };

  filterOrgDataByRegion = region => {
    let filteredOrgs = this.state.rawData.filter(org => {
      if (region) {
        if (org.targetRegion ){
          return org.targetRegion === region;
        }
        else if (org.primaryAddress && org.primaryAddress.region) {
          return org.primaryAddress.region === region;
        } else {
          return [];
        }      
      } else {
        return org;
      }
    });
    let treeData = filteredOrgs.map(item => {
      return this.traverse(item);
    });
    this.setState({
      treeData: treeData
    });
  };

  switchRegion = value => {
    this.setState({ Region: value.Region, defaultOrgId: undefined }, () => {
      this.filterOrgDataByRegion(value.Region);

      if (!value.Region && this.props.setSelectedOrgId) {
        this.props.setSelectedOrgId(this.state.defaultOrgId);
      }
    });
  };

  onOrgLoaded = () => {
    this.setState({ loadingOrg: false });
  };

  render() {
    let { Region, loadingOrg, allRegions, defaultOrgId } = this.state;
    const { Option } = Select;

    return (
      <Row
        gutter={3}
        type="flex"
        justify="end"
        className="orgSelectTreeContainer"
      >
        <Col span={8}>
          <Select
            placeholder="Region"
            allowClear={true}
            loading={loadingOrg}
            showSearch={false}
            style={{ width: '100%' }}
            value={this.state.allRegions.length > 0 ? Region : undefined}
            name="Region"
            onChange={value => {
              this.switchRegion({ Region: value });
            }}
            disabled={loadingOrg}
          >
            {allRegions.length &&
              allRegions.map(i => {
                return (
                  <Option key={i.fullName} value={i.shortName}>
                    {i.fullName}
                  </Option>
                );
              })}
          </Select>
        </Col>
        <Col span={16}>
          <TreeSelect
            getPopupContainer={() => {
              return document.querySelector('.orgSelectTreeContainer');
            }}
            allowClear
            style={{ width: '100%' }}
            loading={loadingOrg}
            value={this.state.treeData.length > 0 ? defaultOrgId : undefined}
            treeData={this.state.treeData}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Select organization"
            treeDefaultExpandAll
            onChange={this.onChange}
            showSearch={true}
            filterTreeNode={this.filterOption}
            disabled={loadingOrg}
            multiple={this.state.multiple}
          ></TreeSelect>
        </Col>
      </Row>
    );
  }
}
