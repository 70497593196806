import axios from 'axios';

export const apiBaseUrl = (URLType) => axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        if (!data || !data[URLType]) {
            return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
        }
        localStorage.setItem(URLType, data[URLType]);
        return data[URLType];
    });

export const getRegion = () => axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        if (!data || !data["Region"]) {
            return '';
        }

        return data["Region"];
    });

export const getAppFullName = () => axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        // if (data.AppName && data.AppVersion) {
        //     return `${data.AppName} ${data.AppVersion}`;
        // }

        if (data.AppName) {
            return `${data.AppName}`;
        }

        return "";
    });