
const initialState = {
    solutions: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SOLUTIONS':
            return {
                ...state,
                solutions: action.payload
            };
        default:
            return state;
    }
}

export default reducer;