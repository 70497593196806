import React, { Component } from 'react';
import {Modal, Form, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styles from './newVouchers.module.scss'


class MultiSerialNumbersInput extends Component {
    state = {
        isValidating: false,
        lineCount: 0,
        machineSerialNumbers: []
    }

    hasErrors = (fieldsError) => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    };

    validateMachineSerialNumbers = (rule, value, callback) => {
        this.setState({ isValidating: true });
        if (value && value.trim()) {
            let serialNumberArray = value.split("\n");
            this.setState({lineCount: serialNumberArray.length});
            let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
            let duplicatedArray = [...new Set(findDuplicates(serialNumberArray))]
            let serialLengthArray = serialNumberArray.filter(y => y.length > 20);
            if (serialLengthArray.length > 0){
                callback(`Length of machine serial number(s) ${serialLengthArray.toString()} is more than 20`);
            } else if(serialNumberArray.length > 100){
                callback('Maximum 100 Machine serial numbers are allowed to enter');
            } else if (duplicatedArray.length > 0){
                callback(`Machine serial number(s) ${duplicatedArray} is/are duplicated`);
            } 
            else {
                callback();
            }   
            this.setState({ isValidating: false });   
        } 
        else if (!value) {
            callback();
            this.setState({ isValidating: false, lineCount: 0 });
        } else {
            callback('The serial number consists entirely of blank spaces');
            this.setState({ isValidating: false, lineCount: 0  });
        }
    }

    onSubmitSN = () => {
        const form = this.props.form;
        form.validateFields(async (err, data) => {
            if (err) {
                return;
            }

            const machineSerialNumberList = data && data.machineSerialNumbers ? data.machineSerialNumbers.split("\n") : null;
            this.setState({ machineSerialNumbers: machineSerialNumberList}, () => {
                this.props.onSubmit(machineSerialNumberList);
                this.props.toggle();
            });
        });
    }

    formatSerialNumbers = (value) => {
        let str = '';

        if (!value) {
            str = undefined;
        } else if (Array.isArray(value) && value.length > 0) {
            str = value.join("\n");
        } else if (Array.isArray(value) && value.length === 0) {
            str = undefined;
        } else {
            str = value;
        }

        return str;
    }

    render() {
        const { isValidating } = this.state;
        const { preValue} = this.props;
        const { getFieldDecorator, getFieldsError } = this.props.form;
        return (
            <>
            <Modal maskClosable={false}
                closable={true}
                title={'Machine Serial Numbers'}
                visible={true}
                bodyStyle={{ padding: '10px' }}
                footer={null}
                width='500px'
                onCancel={this.props.toggle}
                >
                    <Form.Item label='Press enter one or more (multi lines)' hasFeedback>
                        {getFieldDecorator('machineSerialNumbers', {
                        initialValue: this.formatSerialNumbers(preValue),
                        rules: [
                            {  max: 2000, 
                                message: 'The maximum length for machine serial number is 2000 characters' 
                            },
                            { validator:  this.validateMachineSerialNumbers }
                        ],
                        })(<TextArea className={styles.textarea}
                            autoComplete="off" 
                            rows={8} 
                            maxLength={2000}
                            />)}
                    </Form.Item>
                    <div className={styles.multiSNSearchButton}> 
                        <Button 
                            key="cancel" 
                            type="primary" 
                            icon="close" 
                            onClick={this.props.toggle}
                            style={{marginRight: '10px'}}
                        >
                            Cancel
                        </Button>
                        <Button 
                            disabled={ this.hasErrors(getFieldsError()) || isValidating } 
                            key="submit" 
                            type="primary"
                            icon='check'
                            onClick={this.onSubmitSN}
                        >
                            Search
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const MultiSerialNumbers = Form.create({})(MultiSerialNumbersInput);

export default MultiSerialNumbers;
