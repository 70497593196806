import React from 'react';
import { Pagination, Table } from 'antd';
import { dateFormat, dateSort } from '../../utils/util';
import { getLicenseList, deleteLicense, deleteSelectedLicense } from "./license.service";
import DeleteModal from '../commonComponents/deleteModal'
import authMenu from '../commonComponents/AuthorizedComponent/withAuthMenu';
import styles from './license.module.scss';
import alertMessage from '../commonComponents/alertMessage'
import { Icon, Tooltip } from 'antd';
import { keyBy } from 'lodash'
import moment from 'moment';
import axios from 'axios';

const CancelToken = axios.CancelToken;

const LICENSE_ID = 'ObjectID';
const LICENSE_TIER = 'LicenseTier';
const TARGET_NAME = 'ObjectName';
const OBJECT_TYPE = 'LicenseType';
const ISSUE_DATE = 'IssueDate';
const EXPIRY_DATE = 'ExpiryDate';
const TARGET_ID = 'LicensedObjectID';
const SOLUTION_AND_ROLE = 'SolutionNRole';
const DELETE_MODAL_TITLE = 'Delete Subscription';

const headerFormat = {
  [OBJECT_TYPE]: '',
  [LICENSE_ID]: 'Key',
  [LICENSE_TIER]: 'Package',
  [TARGET_NAME]: 'Object Name',
  [SOLUTION_AND_ROLE]: 'Solution & Role',
  [ISSUE_DATE]: 'Issue Date',
  [EXPIRY_DATE]: 'Expiry Date'
}
export { LICENSE_ID, LICENSE_TIER, TARGET_NAME, TARGET_ID, OBJECT_TYPE, ISSUE_DATE, EXPIRY_DATE, SOLUTION_AND_ROLE }

const headerList = [OBJECT_TYPE, LICENSE_ID, LICENSE_TIER, TARGET_NAME, SOLUTION_AND_ROLE, ISSUE_DATE, EXPIRY_DATE];

const columnConfig = headerList.map(item => ({
  key: item,
  dataIndex: item,
  title: headerFormat[item],
  width: item === OBJECT_TYPE ? '3%' : item === LICENSE_ID ? '24%' : item === LICENSE_TIER ? '10%' : item === TARGET_NAME ? '14%' : item === SOLUTION_AND_ROLE ? '20%' : '14%',
  sorter: item === ISSUE_DATE || item === EXPIRY_DATE ? (rowA, rowB) => dateSort(rowA[item], rowB[item]) : false,
  defaultSortOrder: item === EXPIRY_DATE ? 'ascend' : undefined,
  render: (text, record) => {
    switch (item) {
      case EXPIRY_DATE:
        let period = getSubscriptionPeriod(record.ExpiryDate, record.LicenseTier);
        let color = 'green'
        switch (period) {
          case 0:  // Expired
            color = 'red'
            break;
          case 1:  // Expiring
            color = 'yellow'
            break;
          case 2:  // Normal
            color = 'green'
            break;
          default:
            break;
        }
        return <div style={{ color }}>{dateFormat(text)}</div>
      case ISSUE_DATE:
        return dateFormat(text);
      case OBJECT_TYPE:
        return <Tooltip placement="right" title={`${text}-Type`}><Icon type={text === 'User' ? 'user' : 'hdd'} /></Tooltip>
      case SOLUTION_AND_ROLE:
        let solutionHeader = '';
        let solutionRole = '';
        if (record.LicenseType === 'Asset') {
          solutionHeader = record.LicensedAsset ? (record.LicensedAsset.SolutionName === 'HxGN SFx | Asset Management' ? 'Nexus | Metrology Asset Manager' : record.LicensedAsset.SolutionName)
            : record.ParentID
        } else if (record.LicenseType === 'User') {
          solutionHeader = record.LinkedSolution ? record.LinkedSolution.SolutionName : record.ParentID
          solutionRole = record.LinkedSolution ? record.LinkedSolution.RoleName : record.SlnRoleID
        } else {
          // Currently nothing to do.
        }
        const solutionNRole = (
          <div className={styles.customCell} key={text}>
            <h3 className={styles.customCellHeader}>{solutionHeader}</h3>
            <p className={styles.customCellText}>{solutionRole}</p>
          </div>
        );
        return solutionNRole;
      case TARGET_NAME:
        let targetName = '';
        let tragetSerialNumber = '';
        if (record.LicenseType === 'Asset') {
          targetName = record.LicensedAsset ? record.LicensedAsset.AssetName : '';
          tragetSerialNumber = record.LicensedAsset ? record.LicensedAsset.AssetSerialNumber : '';
        } else {
          targetName = record.LicensedUser ? record.LicensedUser.GivenName + ' ' + record.LicensedUser.Surname : '';
        }

        const targetObject = (
          <div className={styles.customCell} key={text}>
            <h3 className={styles.customCellHeader}>{targetName}</h3>
            <p className={styles.customCellText}>{tragetSerialNumber}</p>
          </div>
        );
        return targetObject;
      case LICENSE_TIER:
          if (text === 'Pro')
          {
            return "Advanced";
          }
          else if (text === 'Pro-Trial')
          {
            return "Advanced Trial";
          }
          else if (text === 'Base+')
          {
            return "Essential";
          }
          else
          {
            return text;
          }
      default:
        return text;
    }
  }
}))

const paginationConfig = {
  total: 0,
  size: 'default',
  defaultCurrent: 1,
  showSizeChanger: true,
  showDelete: false,
  showTotal: total => `Total ${total} items`
}

const getSubscriptionPeriod = (date, licenseTier) => {
  /*
    Descriptions for return value:
      0  =>  Expired
      1  =>  Expiring
      2  =>  Normal
  */
  const EXPIRING_DAYS = 60;
  let expiryDate = moment(date);
  let expiringStartDate = moment(date).subtract(EXPIRING_DAYS, 'd');
  let utcNow = moment.utc();

  if (expiryDate.isBefore(utcNow)) {
    return 0;
  } else if (expiryDate.isSameOrAfter(utcNow) && expiringStartDate.isBefore(utcNow)) {
    return 1;
  } else {
    return 2;
  }
}

class LicenseTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paginationConfig: {
        ...paginationConfig,
        onChange: this.onPageNumChange,
        onShowSizeChange: this.onPageSizeChange,
      },
      columnConfig,
      isFetching: false,
      selectedRowKeys: []
    }
  }

  componentDidMount() {
    
  let queryAccountID = this.props.nexusAccountID && this.props.organizationID ? this.props.nexusAccountID :  this.props.nexusAccountID ? this.props.nexusAccountID : this.props.organizationID && this.props.organizationID !== "00000000-0000-0000-0000-000000000000" ? this.props.organizationID : null; 
  (this.props.nexusAccountID || this.props.organizationID || this.props.licenseKey) && 
  this.fetchLicense(
      { 
        Key: this.props.licenseKey, 
        AccountID: queryAccountID
      }
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSubscriptionType !== prevProps.selectedSubscriptionType) {
      this.setState({
        paginationConfig: {
          ...this.state.paginationConfig,
          current: 1
        },
        selectedRowKeys: [],
        checkedRow: null
      })
    }

    let queryAccountID = this.props.nexusAccountID && this.props.organizationID ? this.props.nexusAccountID :  this.props.nexusAccountID ? this.props.nexusAccountID : this.props.organizationID && this.props.organizationID !== "00000000-0000-0000-0000-000000000000" ? this.props.organizationID : null;
    if (this.props.fetchNewTable !== prevProps.fetchNewTable || this.props.nexusAccountID !== prevProps.nexusAccountID 
      || this.props.organizationID !== prevProps.organizationID 
      || this.props.licenseKey !== prevProps.licenseKey) 
      {
        (this.props.nexusAccountID || this.props.organizationID || this.props.licenseKey) ? 
        this.fetchLicense (
          {   
            Key:this.props.licenseKey, 
            AccountID: queryAccountID
          }) 
        : this.setState({
          data: [], selectedRowKeys: [],
          checkedRow: null
      });
    }
    if (this.props.editSuccessInfo && this.props.editSuccessInfo !== prevProps.editSuccessInfo) {
      this.changeLicenseData(this.props.editSuccessInfo)
    }
  }

  changeLicenseData = (newLicenses) => {
    const newLicensesObj = keyBy(newLicenses, LICENSE_ID);
    this.setState((prevState) => {
      const newData = prevState.data.map(item => newLicensesObj[item[LICENSE_ID]] ? { ...item, ...newLicensesObj[item[LICENSE_ID]], key: item[LICENSE_ID] } : item)
      return {
        data: newData, selectedRowKeys: [],
        checkedRow: null
      }
    })
  }

  formatData = (data) => data.map(item => ({
    key: item[LICENSE_ID],
    ...item
  }))

  getLicenseListCancelSource = CancelToken.source();
  fetchLicense = (params, index) => {
    this.getLicenseListCancelSource.cancel();
    this.getLicenseListCancelSource = CancelToken.source();
    this.setState({
      isFetching: true,
      selectedRowKeys: []
    })

    getLicenseList(params, { cancelToken: this.getLicenseListCancelSource.token }) // mockFetchLicense()
      .then((response) => {
        this.setState({
          isFetching: false
        })
        if (response && response.data) {
          const formatData = this.formatData(response.data)
          this.setState((prevState) => ({
            data: formatData,
            paginationConfig: {
              ...prevState.paginationConfig,
              current: index ? index : 1,
              total: formatData.length
            }
          }))
        }
      }
      ).catch(error => {
        if (axios.isCancel(error)) { return; }
        this.setState({ data: [], isFetching: false });
      })
  }

  isClickSelectedRow = (clickedRowKey) => {
    const { selectedRowKeys } = this.state;
    return selectedRowKeys.indexOf(clickedRowKey) > -1
  }

  getOperationMenu = (licenseInfo) => {
    const { data, selectedRowKeys } = this.state;
    const sourceData = keyBy(data, 'key');
    const isClickSelectedRow = this.isClickSelectedRow(licenseInfo[LICENSE_ID])

    const editObj = {
      icon: 'edit',
      label: 'Edit',
      onClick: () => {
        if (isClickSelectedRow) {
          const selectedLicenseInfo = [];
          selectedRowKeys.forEach(item => {
            selectedLicenseInfo.push(sourceData[item])
          })
          this.props.onEditLicense && this.props.onEditLicense(selectedLicenseInfo)
        } else {
          this.props.onEditLicense && this.props.onEditLicense(licenseInfo)
        }
      }
    }

    let hideDelete = false;
    if (isClickSelectedRow) {
      hideDelete = selectedRowKeys.some((item) => sourceData[item].LicensedObjectID);
    }

    return hideDelete ? [editObj] : [
      editObj,
      {
        icon: 'delete',
        label: 'Delete',
        onClick: () => {
          this.toggleDeleteModal()
        }
      }
    ]

  }


  showMenu = (selectedRowKeys, licenseInfo) => {
    const menus = this.getOperationMenu(licenseInfo);
    const isClickSelectedRow = this.isClickSelectedRow(licenseInfo[LICENSE_ID])
    authMenu.show(menus,
      {
        title: isClickSelectedRow ? `Selected ${selectedRowKeys.length} Subscriptions` : 'Subscription',
        id: isClickSelectedRow ? '' : licenseInfo[LICENSE_ID],
        titleIcon: 'file-done'
      });
  }

  onRowClick = (licenseInfo) => {
    const { selectedRowKeys } = this.state;
    const isClickSelectedRow = this.isClickSelectedRow(licenseInfo[LICENSE_ID]);
    this.setState({ ObjectID: isClickSelectedRow ? selectedRowKeys : licenseInfo[LICENSE_ID], checkedRow: licenseInfo[LICENSE_ID] })
    this.showMenu(selectedRowKeys, licenseInfo);
  }

  onPageNumChange = (page, pageSize) => {
    this.setState({
      paginationConfig: {
        ...this.state.paginationConfig,
        current: page,
        pageSize: pageSize,
      },
      selectedRowKeys: []
    })
  }

  onPageSizeChange = (current, size) => {
    this.setState({
      paginationConfig: {
        ...this.state.paginationConfig,
        current,
        pageSize: size,
      },
      selectedRowKeys: []
    })
  }


  handleDelete = async () => {
    const { checkedRow, ObjectID } = this.state;
    const { organizationID, nexusAccountID } = this.props;
    const isClickSelectedRow = this.isClickSelectedRow(checkedRow);

    try {
      let accountID = organizationID.replace("00000000-0000-0000-0000-000000000000", "");
      if (isClickSelectedRow) {
        await deleteSelectedLicense({AccountID: accountID, NexusAccountID: nexusAccountID, ObjectIDs: ObjectID }, { catchError: true })
      } else {
        await deleteLicense({ AccountID: accountID, NexusAccountID: nexusAccountID, ObjectID }, { catchError: true })
      }

      this.setState({ selectedRowKeys: [] })
      alertMessage.success('Deleted subscription successfully')
      let currentIndex = this.state.paginationConfig.current
      if (this.checkIsLastRecord()) {
        currentIndex = currentIndex > 1 ? currentIndex - 1 : 1;
      }
      let queryAccountID = nexusAccountID && accountID ? nexusAccountID :  nexusAccountID ? nexusAccountID : accountID ? accountID : null;
      (accountID || nexusAccountID|| this.props.licenseKey) &&
       this.fetchLicense({ Key: this.props.licenseKey, AccountID: queryAccountID }, currentIndex)

    } catch (error) {
      const { data } = error.response;
      if (data) {
        alertMessage.error(data)
      } else {
        alertMessage.error('Delete subscription failed')
      }
    }
  }

  checkIsLastRecord = () => {
    let currentIndex = this.state.paginationConfig.current
    let currentPageSize = this.state.paginationConfig.pageSize
    let total = this.state.paginationConfig.total
    return total - currentPageSize * (currentIndex - 1) === 1
  }

  toggleDeleteModal = () => {
    this.setState((prevState) => {
      return {
        showDelete: !prevState.showDelete
      }
    });
  }

  onTableChange = (pagination, filters, sorter) => {
    this.setState((prevState) => {
      const newColumConfig = prevState.columnConfig.map(item => {
        return item.key === sorter.columnKey ?
          {
            ...item,
            sortOrder: sorter.order
          }
          :
          {
            ...item,
            sortOrder: false
          }

      })
      return { columnConfig: newColumConfig, selectedRowKeys: [], checkedRow: null };
    })
  }

  onSelectChange = selectedRowKeys => {
    authMenu.close()
    this.setState({ selectedRowKeys, checkedRow: null });
  };

  render() {
    let dataSource;
    let paginationConfig = this.state.paginationConfig;
    if (this.props.selectedSubscriptionType === undefined) {
      dataSource = this.state.data;
    } else {
      dataSource = this.state.data.filter(item => item.LicenseType === this.props.selectedSubscriptionType);
    }
    paginationConfig.total = dataSource.length;
    const { selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <>
        <Table
          className={`scroller ${styles.licenseTable}`}
          size="middle"
          dataSource={dataSource}
          columns={this.state.columnConfig}
          pagination={this.state.paginationConfig}
          loading={this.state.isFetching}
          rowSelection={rowSelection}
          scroll={{ y: '45vh' }}
          onRow={(record) => {
            return {
              onClick: () => {
                this.onRowClick(record)
              }
            }
          }}
          onChange={this.onTableChange}
          rowClassName={(record) => record[LICENSE_ID] === this.state.checkedRow ? 'click-row-style' : ''}
        />
        <br />
        {this.state.paginationConfig.total !== 0 && <Pagination {...paginationConfig} />}
        <DeleteModal
          title={DELETE_MODAL_TITLE}
          visible={this.state.showDelete}
          toggle={this.toggleDeleteModal}
          handleOk={this.handleDelete}
          isConfirmRequired={false}
        />
      </>
    )
  }
}

export default LicenseTable;
