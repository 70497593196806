import popupMenu from '../popupMenu/index'
import { getMainAuth } from './util'
const authMenu = {
  show: (menuList, ...props) => {
    let authList = []
    const readOnlyAuthList = menuList.filter(i => i.label === 'Detail' || i.label === 'Subscription')
    const available = getMainAuth()
    authList = available ? menuList : readOnlyAuthList
    if (authList.length <= 0) return
    return popupMenu.show(authList, ...props)
  },
  close: () => {
    popupMenu.close()
  }
}
export default authMenu