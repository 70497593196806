import apiService from '../../services/api.service'

export const AddOrgApi = (params, opts) => {
    return apiService.post('/api/organizations', params, opts);
};

export const EditOrgApi = (params, opts) => {
    return apiService.put('/api/organizations', params, opts);
};

export const GetOrgByIdOrNameApi = (params, opts) => {
    return apiService.get(`/api/organizations/${encodeURIComponent(params)}`, params, opts);
};
export const CheckOrgApi = (params, opts) => {
    return apiService.get(`/api/organizations/check/${encodeURIComponent(params.orgName)}/${params.orgId}`, params, opts);
};
export const CheckExecSalesFieldsApi = (params, opts) => {
    return apiService.get(`/api/organizations/checkField/${encodeURIComponent(params.fieldName)}/${encodeURIComponent(params.fieldValue)}/${params.orgId}`, params, opts);
};

export const GetOrgTreeApi = (params, opts) => {
    return apiService.get('/api/organizations/tree', params, opts);
};

export const GetAllOrgApi = (params, opts = {}) => {
    //NOTE: The apiService has an issue with get method, need to set the opts twice here to make the catchError and request config both working.
    return apiService.get('/api/organizations', { params: params, ...opts }, opts);
};

export const DisableOrgApi = (orgId, opts) => {
    return apiService.put(`/api/organizations/${orgId}/disable`, orgId, opts);
};
export const EnableOrgApi = (orgId, opts) => {
    return apiService.put(`/api/organizations/${orgId}/enable`, orgId, opts);
};

export const GetOrgSolutionsApi = (orgId, opts) => {
    return apiService.get(`/api/organizations/${orgId}/solutions`, orgId, opts);
};

export const AddOrgSolutionsApi = (params, opts) => {
    return apiService.put('/api/organizations/solutions', params, opts);
};

export const PutLinkUserApi = (params, opts) => {
    return apiService.put('/api/organizations/users', params, opts);
};
export const GetOrgLinkedUsersApi = (orgId, opts) => {
    return apiService.get(`/api/organizations/${orgId}/users`, orgId, opts);
};
export const GetUsersNoOrgApi = (params, opts) => {
    return apiService.get('/api/users/noorg', params, opts);
};
export const GetUserRolesApi = (params, opts) => {
    return apiService.get(`/api/users/slnRoles/${params}`, params, opts);
};
export const AddUserRolesApi = (params, opts) => {
    return apiService.put(`/api/users/roles`, params, opts);
};
export const DeleteOrgApi = (orgId, opts) => {
    return apiService.delete(`/api/organizations/${orgId}`, orgId, opts);
};

export const GetCountryApi = (params, opts) => {
    return apiService.get(`/api/geo/admin/country`, params, opts);
};
export const GetStateApi = (countryGeoNameId, opts) => {
    return apiService.get(`/api/geo/admin/${countryGeoNameId}/state`, countryGeoNameId, opts);
};
export const GetCityApi = (stateGeoNameId, opts) => {
    return apiService.get(`/api/geo/admin/${stateGeoNameId}/city`, '', opts);
};
export const GetCallingCodeApi = (params, opts) => {
    return apiService.get(`/api/geo/admin/callingcodes`, params, opts);
};

export const GetSfxRoles = () => {
    return apiService.get('/api/sfx/roles').then(({ data }) => {
        return data || []
    })
};

export const PostLogoImgApi = (orgId, logoFileId, params, opts) => {
    return logoFileId ? apiService.post(`/api/organizations/${orgId}/logo/${logoFileId}`, params, opts) :
        apiService.post(`/api/organizations/${orgId}/logo`, params, opts);
};

export const DeleteLogoImgApi = (orgId, opts) => {
    return apiService.delete(`/api/organizations/${orgId}/logo`, '', opts);
};

export const GetOrgTagsApi = () => {
    return apiService.get('/api/tags').then(({ data }) => {
        return data || []
    })
};

export const SaveOrganizationConfig = (organizationId, configItems, opts) => {
    return apiService.post(`/api/organizations/${organizationId}/config`, configItems, opts);
};
