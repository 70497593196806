import React, { Component } from 'react';
import { Modal, Row, Col, Input, Form } from 'antd';
import { connect } from "react-redux";

import apiService from '../../../services/api.service'

class DeleteModalForm extends Component {
    state = {
        confirmLoading: false,
        showTip: false,
        userName: this.props.user && this.props.user.profile && `${this.props.user.profile.given_name} ${this.props.user.profile.family_name}`
    }
    static defaultProps = {
        isConfirmRequired: true
    }
    afterClose = () => {
        this.props.form.setFieldsValue({ password: '' })
        this.setState({ confirmLoading: false, showTip: false })
    }
    handleOk = e => {
        e.preventDefault();
        // there should be a request
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                if (this.props.isConfirmRequired) {
                    apiService.post('/api/account/identity', values, { catchError: true }).then(res => {
                        const { data } = res
                        if (data) {
                            this.props.handleOk()
                            this.setState({
                                confirmLoading: false,
                            });
                            this.props.toggle()
                        } else {
                            this.setState({
                                confirmLoading: false,
                                showTip: true
                            })
                        }

                    }).catch(error => {
                        this.setState({
                            confirmLoading: false,
                            showTip: true
                        })
                    })
                } else {
                    this.props.handleOk();
                    this.props.toggle();
                    this.setState({
                        confirmLoading: false
                    })
                }
            }
        });
    }

    handleCancel = e => {
        if (this.props.handleCancel) {
            this.props.handleCancel()
        } else {
            this.props.toggle()
        }
    };
    onChange = e => {
        this.setState({
            confirmLoading: false,
            showTip: false
        })
    }
    render() {
        const confirmLoading = this.state.confirmLoading
        const { getFieldDecorator, getFieldError, isFieldTouched, getFieldValue } = this.props.form;
        const passwordError = isFieldTouched('password') && getFieldError('password');
        const passwordValue = getFieldValue('password')

        return (

            <Modal
                maskClosable={!confirmLoading}
                closable={!confirmLoading}
                afterClose={this.afterClose}
                title={this.props.title}
                visible={this.props.visible}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                width='650px'
                okButtonProps={{ disabled: this.props.isConfirmRequired && !passwordValue }}>
                <h2>{this.props.title}</h2>
                <h4> {this.state.userName} </h4>
                {this.props.description && <p><span className="required">* </span> This action will permanently delete the organization, the sub-organizations under the organization, and all users under the organization and sub-organizations.</p>}
                <p>Are you sure you want to {this.props.title.toLowerCase().replace(' sfx ', ' SFx ')}?</p>
                {this.props.isConfirmRequired &&
                    <>
                        <p>This action cannot be undone. Enter your password below to confirm.</p>
                        <Form >
                            <Row type="flex" justify="space-around" align="middle">
                                <Col span={12}>
                                    <br />
                                    <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'Please input your Password!' }],
                                        })(
                                            <Input.Password placeholder="Password" onChange={this.onChange} disabled={this.state.confirmLoading} />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                    {this.state.showTip && <span className="alert-wrap">
                                        <img src='/images/alert-outlinesvg.svg' alt='icon' /><span>
                                            <p>Password is incorrect.</p><p> Enter correctly to {this.props.title.toLowerCase().replace(' sfx ', ' SFx ')}.</p></span>
                                    </span>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
            </Modal>
        )
    }
}

const DeleteModal = Form.create({ name: 'DeleteModalForm' })(DeleteModalForm);
const mapStateToProps = state => ({
    user: state.user.user
})
export default connect(mapStateToProps, {})(DeleteModal)